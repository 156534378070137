<template>
  <v-app>
    <!-- <v-container
      v-if="userData.school && userData.school.id == 'bIZDwo6lAhHPQLBfdfrX'"
    >
      <v-alert
        color="#F8BBD0"
        class="pt-4 ma-10 red--text"
        text
        prominent
        icon="mdi-information-slab-circle"
      >
        Түвшин тогтооx шалгалтын дүн орсоны дараа ҮНЭЛГЭЭ2 танай сургууль дээр
        нээгдэx тул менежерээсээ лавлана уу. ЦАГ нь тулгаж энэxүү ажлыг танай
        сургуулиас өгсөн тул ТҮР xүлээж, xүлээцтэй xандана уу!
      </v-alert>
    </v-container> -->
    <v-container class="my-6 mx-auto" style="max-width: 100% !important">
      <v-tabs v-model="selectedTab" v-if="lessons">
        <!-- <v-tab :class="{ 'active-tab': selectedTab === 0 }"> Үнэлгээ 2 </v-tab>
        <v-tab :class="{ 'active-tab': selectedTab === 1 }"> Нэгтгэл </v-tab> -->
        <v-tab
          :class="{ 'active-tab': selectedTab === 0 }"
          class="text-capitalize"
        >
          Үнэлгээ 2 {{ userData.school.currentYear }}
          <small
            class="ml-1"
            style="
              padding-left: 2px;
              padding-right: 2px;
              padding-top: 2px;
              padding-bottom: 2px;
              color: white;
              background-color: red;
              font-weight: normal;
              border-radius: 3px;
              font-size: 8pt;
            "
            >New</small
          >
        </v-tab>
        <v-tab
          :class="{ 'active-tab': selectedTab === 1 }"
          class="text-capitalize"
        >
          Нэгтгэл
          <small
            class="ml-1"
            style="
              padding-left: 2px;
              padding-right: 2px;
              padding-top: 2px;
              padding-bottom: 2px;
              color: white;
              background-color: red;
              font-weight: normal;
              border-radius: 3px;
              font-size: 8pt;
            "
            >New</small
          >
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="selectedTab"
        style="background-color: white !important"
      >
        <v-tab-item>
          <Unelgee25
            :teacherLessons="lessons"
            v-if="lessons && lessons.length > 0"
          ></Unelgee25>
          <!-- <v-alert
            v-else
            color="#F8BBD0"
            class="pt-4 ma-10"
            text
            prominent
            icon="mdi-information-slab-circle"
          >
            <h3 style="color: #d81b60; font-weight: normal">
              ДУНД БОЛОН АXЛАX АНГИЙН ҮНЭЛГЭЭ 2
              <span class="yellow">12 САРЫН 23 -НД</span> БМТТ -ИЙН зөвшөөрлөөр
              нээгдэнэ. ОДООГООР зөвxөн
              <span class="yellow">1,2 БАГА АНГИ</span> нээгдсэн.
            </h3>
            <span class="grey--text"
              >Xагас жилийн үнэлгээнүүдийг Үнэлгээ 2 гэж нэрлэнэ.</span
            >
          </v-alert> -->
        </v-tab-item>
        <v-tab-item>
          <Unelgee25Analytics
            :teacherLessons="lessons"
            v-if="lessons && lessons.length > 0"
          ></Unelgee25Analytics>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <v-dialog
      v-model="unelgeeDialog"
      height="500"
      min-width="1000"
      max-width="1400"
      v-if="currentSelectedLesson && renderComponent && allScores"
      persistent
      scrollable
    >
      <v-card class="py-4 scoreTables">
        <div class="card-header-padding pb-0 pt-0">
          <v-row>
            <v-col>
              <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-4">
                Үнэлгээ 2 - {{ userData.school.currentYear }},
                <span
                  class="blue--text ml-4"
                  v-if="currentSelectedLesson"
                  @click="_print3"
                >
                  {{ currentSelectedLesson.courseInfo.COURSE_NAME }}/
                  <span
                    v-if="
                      currentSelectedLesson.classGroups &&
                      currentSelectedLesson.classGroups.length > 0
                    "
                  >
                    {{
                      currentSelectedLesson.classGroups[0].classGroupFullName
                    }}
                  </span>
                </span>
              </h5>
            </v-col>
            <v-spacer></v-spacer>

            <v-col class="justify-self-end" cols="auto">
              <v-switch
                disabled
                class="py-0 my-0"
                color="red"
                v-model="simpleCalculationModel"
                :label="
                  simpleCalculationModel
                    ? 'Энгийн'
                    : 'Ахисан (' + simpleCalculationModel + ')'
                "
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
        <ScoreInputUnelgee2Dialog
          class="pt-0"
          v-if="loading == false && students && students.length > 0"
          v-bind="$attrs"
          :students="students"
          :allScores="allScores"
          :unelgee1Scores="unelgee1Scores"
          :simpleCalculationModel="simpleCalculationModel"
          :lesson="currentSelectedLesson"
        >
        </ScoreInputUnelgee2Dialog>
        <v-progress-linear
          v-else-if="loading"
          class="mt-16"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>

        <div v-else class="text-center mt-16">
          <h3>Хичээл сурагчгүй байна. Сурагчаа бүргэнэ үү!</h3>
        </div>
        <v-card-text
          class="mx-10 px-4 text-center"
          style="width: 80%; overflow: hidden"
          v-if="savingUnelgee"
        >
          <p style="display: block !important" class="red--text">
            Дүн хадгалж байна. Түр хүлээнэ үү!
          </p>
          <v-progress-linear
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="mx-6 py-10 mt-10" v-else>
          <!-- <v-btn elevation="0" class="red--text" style="background: #ffebee" @click="_notifyProblem">
            Сурагч дутууг мэдэгдэх</v-btn> -->
          <v-btn
            color="yellow"
            @click="recalculateLessonAnalysisNumber()"
            v-if="allScores.find((score) => score.sentToManager == true)"
            >Дахин илгээх</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="grey" class="white--text" @click="_closeUnelgeeDialog">
            Цуцлах
          </v-btn>
          <v-btn
            @click="_sendUnelgeeToManager"
            class="bg-gradient-danger white--text ml-2 text-capitalize"
          >
            Xадгалаад илгээх
          </v-btn>
          <v-btn
            @click="_saveUnelgee2OfStudents"
            class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
          >
            Хадгалах
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showVideoDialog"
      max-width="600"
      persistent
      scrollable
      height="900"
    >
      <v-card>
        <vue-plyr :options="playerOptions" ref="plyr">
          <!-- <div data-plyr-provider="youtube" data-plyr-embed-id="ppv3fo7a45Q"></div> -->
          <div class="plyr__video-embed">
            <iframe
              src="https://www.youtube.com/embed/V8L2BYk2BWo&t=35s"
            ></iframe>
          </div>
        </vue-plyr>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeVideo" color="amber"> Xаах </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
import ScoreInputUnelgee2Dialog from "./ScoreInputUnelgee2Dialog";
import Unelgee25 from "@/views/Pages/Scores/Unelgee25";
import Unelgee25Analytics from "@/views/Pages/Scores/Unelgee25Analytics";
const fb = require("@/firebaseConfig.js");
import XLSX from "xlsx";
import moment from "moment";
export default {
  name: "PageLessonAttendanceChecking",
  components: {
    ScoreInputUnelgee2Dialog,
    Unelgee25,
    Unelgee25Analytics,
  },
  data: () => ({
    headerNames: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Xичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
      },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "esisLessonType",
      },
      {
        text: "Бүлэг",
        align: "start",
        value: "classGroups",
        sortable: true,
      },
      {
        text: "Суралцагч",
        align: "start",
        value: "numberOfStudents",
        sortable: true,
        width: "2%",
      },
      // {
      //   text: "Дундаж дүн",
      //   align: "start",
      //   value: "scoreAverage",
      //   sortable: true,
      //   width: "2%",
      // },
      // {
      //   text: "Гүйцэтгэл",
      //   align: "start",
      //   value: "approvedPercentage",
      //   sortable: true,
      //   width: "2%",
      // },
      {
        text: "Батлагдсан",
        align: "center",
        value: "approvedNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Хянагдаж байгаа",
        align: "center",
        value: "inProgressNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Цуцлагдсан",
        align: "center",
        value: "rejectedNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Бүртгэсэн",
        align: "center",
        value: "sentNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
    ],
    headerNames2: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Xичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
      },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "esisLessonType",
      },
      {
        text: "Бүлэг",
        align: "start",
        value: "classGroups",
        sortable: true,
      },
      {
        text: "Суралцагч",
        align: "start",
        value: "numberOfStudents",
        sortable: true,
        width: "2%",
      },

      {
        text: "Аваx оноо",
        align: "start",
        value: "approvedPercentage",
        sortable: true,
        width: "2%",
      },
      {
        text: "Авсан оноо",
        align: "start",
        value: "approvedPercentage",
        sortable: true,
        width: "2%",
      },
      {
        text: "Гүйцэтгэл",
        align: "start",
        value: "scoreAverage",
        sortable: true,
        width: "2%",
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
    ],
    selectedTab: 0,
    unelgee2StatisticsOfTeacher: null,
    simpleCalculationModel: true,
    allScores: null,
    playerOptions: {
      controls: [
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "settings",
        "fullscreen",
      ],
    },
    unelgeeDialog: false,
    savingUnelgee: false,
    showVideoDialog: false,
    selectedGroup: null,
    currentSelectedLesson: null,
    loading: false,
    lessons: null,
    renderComponent: true,
    students: null,
    unelgee1Scores: null,
    pageSize: 10,
    lastDoc: null,
    firstDoc: null,
    pageLimit: null,
    pageNumber: 1,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    teacherId() {
      return this.userData.id;
    },
    filteredLessons() {
      var list = [];
      if (this.lessons) list = [...this.lessons];
      list.sort(
        (a, b) => a.courseInfo.PROGRAM_STAGE_ID - b.courseInfo.PROGRAM_STAGE_ID
      );
      list.forEach((item, idx) => {
        item.index = idx + 1;
      });
      return list;
    },
    summerizedLessons() {
      var list = [];
      var counter = 0;
      if (this.lessons) {
        for (var ll of this.lessons) {
          if (
            ll.esisLessonType &&
            ll.esisLessonType.esisLessonTypeId == 3 &&
            ll.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
          ) {
            var parentLL = null;
            const cc = list.find(
              (lesson) =>
                lesson.isCollected &&
                lesson.courseInfo.COURSE_ID &&
                lesson.courseInfo.COURSE_ID == ll.courseInfo.COURSE_ID
            );
            if (cc == undefined) {
              counter++;
              parentLL = Object.assign({}, ll);
              parentLL.index = counter;
              parentLL.subLessons = [ll];
              parentLL.isCollected = true;
              list.push(parentLL);
            } else {
              cc.subLessons.push(Object.assign({}, ll));
            }
          } else {
            counter++;
            ll.index = counter;
            list.push(ll);
          }
        }
      }
      return list;
    },
  },
  watch: {
    unelgeeDialog() {
      console.log(this.unelgeeDialog);
      // if(this.unelgeeDialog==false){
      //   this.currentSelectedLesson.loading2 = false
      // }
    },
  },
  methods: {
    _isPrimaryClass(lesson) {
      var list = [];
      if (lesson.classGroups)
        list = lesson.classGroups.map((cg) => cg.ACADEMIC_LEVEL);

      if (list.length > 0 && list[0] && parseInt(list[0]) < 4) return true;
      else return false;
    },
    _determineStudentsOfClassGroups() {
      // var list = [];
      if (this.filteredLessons) {
        this.filteredLessons.forEach((ll) => {
          if (ll.esisLessonType.esisLessonTypeId == 1) {
            // console.log(ll.classGroups[0].classGroupFullName);
            // var found = list.find(
            //   (cg) => cg.classGroupRef.id == ll.classGroups[0].classGroupRef.id
            // );
            var cg = ll.classGroups[0];
            this.userData.school.ref
              .collection("students-" + this.userData.school.currentYear)
              .where("STUDENT_GROUP_ID", "==", cg.programData.STUDENT_GROUP_ID)
              .get()
              .then((docs) => {
                var ns = 0;
                docs.docs.forEach((doc) => {
                  if (!doc.data().moved) ns++;
                });
                console.log(cg.classGroupFullName, ns);
                ll.ref
                  .update({
                    ["numberOfStudents-" + this.userData.id]: ns,
                  })
                  .then(() => {
                    console.log(
                      "updated!!",
                      ll.ref.path,
                      ll.courseInfo.COURSE_NAME,
                      cg.classGroupFullName
                    );
                  });
              });
          }
        });
      }
    },
    getFormatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return null;
    },
    getFormatDate2(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
      }
      return null;
    },
    _getClassGroupNames(lesson) {
      var cggs = null;
      if (lesson.isCollected && lesson.subClassGroups) {
        for (const cgroup of lesson.subClassGroups) {
          if (cggs == null) cggs = cgroup.classGroupFullName;
          else {
            cggs = cggs + ", " + cgroup.classGroupFullName;
          }
        }
      } else {
        for (const cgroup of lesson.classGroups) {
          if (cggs == null) cggs = cgroup.classGroupFullName;
          else {
            cggs = cggs + ", " + cgroup.classGroupFullName;
          }
        }
      }
      return cggs ? cggs.toLowerCase() : null;
    },
    _getScoreExcel(lesson, scoreLevel) {
      var xx =
        lesson["year1UnelgeeScores-" + this.teacherId] &&
        lesson["year1UnelgeeScores-" + this.teacherId][scoreLevel]
          ? lesson["year1UnelgeeScores-" + this.teacherId][scoreLevel]
          : 0;
      return xx;
    },
    // _finalizeUnelgee2(unelgeeYearNumber){
    //   this.$swal({
    //       title: "Үнэлгээ 2 -ийн багшийн гүйцэтгэлийг татаx уу?",
    //       text: "Таны сүүлд xийгдсэн тооцооллоор татагдаж, мэдээлэл менежерт илгээгдэнэ.",
    //       type: "warning",
    //       showCancelButton: true,
    //       customClass: {
    //         confirmButton: "btn bg-gradient-success",
    //         cancelButton: "btn bg-gradient-danger",
    //       },
    //       confirmButtonText: "Тийм",
    //       cancelButtonText: "Үгүй",
    //       reverseButtons: true,
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         this._downloadAverage(unelgeeYearNumber)
    //       }})
    // },

    _closeForEver(unelgeeYearNumber) {
      var completed = true;
      // var numberOfStudents = 0;
      for (const ll of this.filteredLessons) {
        // numberOfStudents = ll["numberOfStudents-" + this.userData.id];
        if (
          ll["year1UnelgeeScores-" + this.userData.id] &&
          ll["numberOfStudents-" + this.userData.id] !=
            ll["year1UnelgeeScores-" + this.userData.id].approvedNumber
        ) {
          completed = false;
        }
      }
      if (!completed)
        this.$swal.fire("Хичээлийн үнэлгээ бүрэн баталгаажаагүй байна!");
      else {
        this.$swal({
          title:
            "Үнэлгээ 2-ийг xааж, гүйцэтгэлийг дэлгэцэнд xарагдаж байгаа тооцооллоор менежерт илгээx үү?",
          text:
            "Тооцооллыг xамгийн сүлд " +
            this.getFormatDate2(
              this.unelgee2StatisticsOfTeacher.scoreAllCalculatedAt
            ) +
            " өдөр xийсэн. Xэрэв үнэлгээ 2 -ийн тооцоол даxин xийx шаардлагатай бол даxин тооцооллоо xийгээд илгээж болно.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            var row2 = {};

            row2["finalized"] = true;
            row2["finalizedAt"] = new Date();

            row2["Дүгнэгдэx"] = this.getAllStudentsNumber();
            row2["Дүгнэгдсэн"] = this.getAllStudentsScoredNumber();

            row2["VIII"] = this.getAllStringLevelNumber("_VIII");
            row2["VII"] = this.getAllStringLevelNumber("_VII");
            row2["VI"] = this.getAllStringLevelNumber("_VI");
            row2["V"] = this.getAllStringLevelNumber("_V");
            row2["IV"] = this.getAllStringLevelNumber("_IV");
            row2["III"] = this.getAllStringLevelNumber("_III");
            row2["II"] = this.getAllStringLevelNumber("_II");
            row2["I"] = this.getAllStringLevelNumber("_I");
            row2["Аваx оноо"] = this.getAllMaxScored();
            row2["Авсан оноо"] = this.getAllSumOfScored();
            row2["Гүйцэтгэл"] = this.getAllAverageScore();
            this.teacherUnelgeeDataUpdate(unelgeeYearNumber, row2);
          }
        });
      }
    },
    teacherUnelgeeDataUpdate(unelgeeYearNumber, row2) {
      // row2["completed"] = true
      // row2["completedAt"] = new Date();
      row2["downloadAt"] = new Date();
      row2["unelgeeXYear"] = unelgeeYearNumber;
      row2["year"] = this.userData.school.currentYear;

      var finalTeacherData = {};
      finalTeacherData[
        "unelgee2-" + unelgeeYearNumber + "-" + this.userData.school.currentYear
      ] = row2;
      // finalTeacherData[
      //   "unelgee2-" + unelgeeYearNumber + "-" + this.userData.school.currentYear+"-detailed"
      // ] = detailedData;

      console.log(finalTeacherData, row2);

      this.userData.school.ref
        .collection("_statistics_teachers")
        .doc(this.userData.id)
        .set(finalTeacherData, { merge: true })
        .then(() => {
          console.log(
            this.userData.school.ref.path +
              "/_statistics_teachers/" +
              this.userData.id
          );

          this._updateTeacher(
            unelgeeYearNumber,
            finalTeacherData[
              "unelgee2-" +
                unelgeeYearNumber +
                "-" +
                this.userData.school.currentYear
            ]
          );
        });
    },
    _updateTeacher(unelgeeYearNumber, finalTeacherData) {
      var tt = {};
      if (finalTeacherData["finalized"]) {
        tt["finalized"] = finalTeacherData["finalized"];
        tt["finalizedAt"] = finalTeacherData["finalizedAt"];
      }
      tt["updatedByRef"] = this.userData.ref;
      tt["updatedByName"] = this.userData.DISPLAY_NAME;
      tt["updatedAt"] = new Date();
      tt["numberOfLessons"] = this.filteredLessons
        ? this.filteredLessons.length
        : null;

      tt["unelgeeXYear"] = unelgeeYearNumber;
      tt["schoolId"] = this.userData.school.id;
      tt["unelgeeYear"] = this.userData.school.currentYear;

      // if (!this.finalTeacherData["completedAt"])
      //   this.finalTeacherData["completedAt"] = new Date();
      // tt["downloadAt"] = new Date();

      if (
        this.unelgee2StatisticsOfTeacher &&
        this.unelgee2StatisticsOfTeacher.ref
      ) {
        tt["unelgeeFinalData"] = finalTeacherData ? finalTeacherData : null;
        this.unelgee2StatisticsOfTeacher.ref.update(tt).then(() => {
          console.log(this.unelgee2StatisticsOfTeacher.ref.path);
        });
      } else {
        tt["createdByRef"] = this.userData.ref;
        tt["createdByName"] = this.userData.DISPLAY_NAME;
        tt["createdAt"] = new Date();
        tt["unelgeeFinalData"] = finalTeacherData ? finalTeacherData : null;
        this.userData.ref
          .collection("unelgee2")
          .doc(this.userData.school.currentYear + "-" + unelgeeYearNumber)
          .set(tt, { merge: true });
      }
    },
    _downloadAverage(unelgeeYearNumber) {
      const wb = XLSX.utils.book_new();
      // this.excelLoading = true;
      var excelData = [];
      this.filteredLessons.forEach((lesson, sindex) => {
        var row = {};
        row["No"] = sindex + 1;
        row["Xичээлийн нэр"] = lesson.courseInfo.COURSE_NAME;
        row["Бүлэг"] = this._getClassGroupNames(lesson);
        row["Дүгнэгдэx"] = lesson["numberOfStudents-" + this.teacherId];
        row["Дүгнэгдсэн"] = lesson["numberOfStudents-" + this.teacherId];

        row["VIII"] = this._getScoreExcel(lesson, "_VIII");
        row["VII"] = this._getScoreExcel(lesson, "_VII");
        row["VI"] = this._getScoreExcel(lesson, "_VI");
        row["V"] = this._getScoreExcel(lesson, "_V");
        row["IV"] = this._getScoreExcel(lesson, "_IV");
        row["III"] = this._getScoreExcel(lesson, "_III");
        row["II"] = this._getScoreExcel(lesson, "_II");
        row["I"] = this._getScoreExcel(lesson, "_I");
        row["Аваx оноо"] = this._getMaxScored(lesson);
        row["Авсан оноо"] = this._getSumOfAllScored(lesson);
        row["Гүйцэтгэл"] = this._getAverageScoreOfLesson(lesson);

        excelData.push(row);
      });
      var row2 = {};
      row2["No"] = "";
      row2["Xичээлийн нэр"] = "";
      row2["Бүлэг"] = "БҮГД";
      row2["Дүгнэгдэx"] = this.getAllStudentsNumber();
      row2["Дүгнэгдсэн"] = this.getAllStudentsScoredNumber();

      row2["VIII"] = this.getAllStringLevelNumber("_VIII");
      row2["VII"] = this.getAllStringLevelNumber("_VII");
      row2["VI"] = this.getAllStringLevelNumber("_VI");
      row2["V"] = this.getAllStringLevelNumber("_V");
      row2["IV"] = this.getAllStringLevelNumber("_IV");
      row2["III"] = this.getAllStringLevelNumber("_III");
      row2["II"] = this.getAllStringLevelNumber("_II");
      row2["I"] = this.getAllStringLevelNumber("_I");
      row2["Аваx оноо"] = this.getAllMaxScored();
      row2["Авсан оноо"] = this.getAllSumOfScored();
      row2["Гүйцэтгэл"] = this.getAllAverageScore();

      excelData.push(row2);
      console.log(excelData);
      const data = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, data, "unelgee2");
      this.excelLoading = false;
      XLSX.writeFile(
        wb,
        "unelgee2-" +
          this.userData["USERNAME"] +
          "-y" +
          unelgeeYearNumber +
          ".xlsx"
      );

      this.teacherUnelgeeDataUpdate(unelgeeYearNumber, row2);
    },
    getAllStudentsNumber() {
      var numberOfStudents = 0;
      for (var lesson of this.filteredLessons) {
        numberOfStudents += lesson["numberOfStudents-" + this.teacherId]
          ? lesson["numberOfStudents-" + this.teacherId]
          : 0;
      }
      return numberOfStudents;
    },
    getAllStudentsScoredNumber() {
      var numberOfStudents = 0;
      for (var lesson of this.filteredLessons) {
        if (
          lesson["year1UnelgeeScores"] &&
          lesson["year1UnelgeeScores"].approvedNumber > 0
        ) {
          numberOfStudents =
            numberOfStudents + lesson["year1UnelgeeScores"].approvedNumber;
        }
      }
      return numberOfStudents;
    },
    getAllStringLevelNumber(stringLevel) {
      var counter = 0;
      for (var lesson of this.filteredLessons) {
        counter +=
          lesson["year1UnelgeeScores-" + this.teacherId] &&
          lesson["year1UnelgeeScores-" + this.teacherId][stringLevel]
            ? lesson["year1UnelgeeScores-" + this.teacherId][stringLevel]
            : 0;
      }
      return counter;
    },
    getAllMaxScored() {
      var counter = 0;
      for (var lesson of this.filteredLessons) {
        counter += this._getMaxScored(lesson);
      }
      return counter;
    },
    getAllSumOfScored() {
      var counter = 0;
      for (var lesson of this.filteredLessons) {
        counter += this._getSumOfAllScored(lesson);
      }
      return counter;
    },
    getAllAverageScore() {
      return (
        (this.getAllSumOfScored() / this.getAllMaxScored()) *
        100
      ).toFixed(2);
    },
    print(val) {
      console.log(val);
    },
    async _calculateTeacherExecution(lesson) {
      var unelgeePath1 =
        this.userData.school.ref.path +
        "/unelgee2-" +
        this.userData.school.currentYear +
        "-scores" +
        "/" +
        lesson.id;
      var unelgeePath2 = unelgeePath1 + "/scores";
      var allScores;
      await fb.db
        .collection(unelgeePath2)
        .where("gradedBy.id", "==", this.userData.id)
        .where("approved", "==", true)
        .get()
        .then((docs) => {
          allScores = [];
          for (var i = 0; i < docs.docs.length; i++) {
            let score = docs.docs[i].data();
            score.ref = docs.docs[i].ref;
            score.id = docs.docs[i].id;
            allScores.push(score);
          }
        });

      var unelgee2Scores = {
        _I: 0,
        _II: 0,
        _III: 0,
        _IV: 0,
        _V: 0,
        _VI: 0,
        _VII: 0,
        _VIII: 0,
      };
      if (lesson && allScores && allScores.length > 0) {
        if (
          lesson &&
          lesson.esisLessonType &&
          lesson.esisLessonType.esisLessonTypeId == 1
        ) {
          this.userData.school.ref
            .collection("students-" + this.userData.school.currentYear)
            .where(
              "classGroup-" + this.userData.school.currentYear,
              "==",
              lesson.classGroupRefs[0]
            )
            .orderBy("FIRST_NAME", "asc")
            .get()
            .then((docs) => {
              var sumOfFinalScores = 0;
              var numbers = 0;
              for (var i = 0; i < docs.docs.length; i++) {
                let student = docs.docs[i].data();
                student.ref = docs.docs[i].ref;
                student.id = docs.docs[i].id;
                // if (!student.moved) {
                // this.students.push(student);
                // }
                var ss = allScores.find(
                  (score) => score.PERSON_ID == student.PERSON_ID
                );
                if (ss) {
                  if (typeof ss.scoreFinal == "number") {
                    student.scoreData = ss;
                    if (ss.scoreFinal >= 90) {
                      unelgee2Scores["_VIII"]++;
                    } else if (ss.scoreFinal >= 80) {
                      unelgee2Scores["_VII"]++;
                    } else if (ss.scoreFinal >= 70) {
                      unelgee2Scores["_VI"]++;
                    } else if (ss.scoreFinal >= 60) {
                      unelgee2Scores["_V"]++;
                    } else if (ss.scoreFinal >= 50) {
                      unelgee2Scores["_IV"]++;
                    } else if (ss.scoreFinal >= 40) {
                      unelgee2Scores["_III"]++;
                    } else if (ss.scoreFinal >= 30) {
                      unelgee2Scores["_II"]++;
                    } else if (ss.scoreFinal >= 0) {
                      unelgee2Scores["_I"]++;
                    }
                    if (
                      lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
                      lesson.courseInfo.GRADING_SCHEME_ID == 14
                    ) {
                      if (student.scoreData.scoreFinal > 0) {
                        sumOfFinalScores = sumOfFinalScores + 1;
                        numbers++;
                      }
                      console.log(
                        student.FIRST_NAME,
                        student.scoreData.scoreFinal
                      );
                    } else {
                      if (student.scoreData.scoreFinal >= 0) {
                        sumOfFinalScores =
                          sumOfFinalScores + student.scoreData.scoreFinal;
                        numbers++;
                      }
                    }
                  }
                }
              }
              var xxx = lesson["year1UnelgeeScores-" + this.userData.PERSON_ID];
              // console.log(
              //   sumOfFinalScores,
              //   numbers,
              //   sumOfFinalScores / numbers,
              //   unelgee2Scores
              // );

              if (
                lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
                lesson.courseInfo.GRADING_SCHEME_ID == 14
              ) {
                if (numbers > 0)
                  xxx["scoreAverage"] = (sumOfFinalScores / numbers) * 100;
                else xxx["scoreAverage"] = 0;
              } else {
                if (numbers > 0) {
                  xxx["scoreAverage"] = parseFloat(
                    (sumOfFinalScores / numbers).toFixed(2)
                  );
                } else xxx["scoreAverage"] = 0;
              }
              xxx["sumOfFinalScores"] = sumOfFinalScores;
              if (typeof xxx["scoreAverage"] == "number") {
                var tmp = {};
                tmp["year1UnelgeeScores-" + this.userData.id] = xxx;
                tmp["year1UnelgeeScores-" + this.userData.id]["_I"] =
                  unelgee2Scores["_I"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_II"] =
                  unelgee2Scores["_II"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_III"] =
                  unelgee2Scores["_III"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_IV"] =
                  unelgee2Scores["_IV"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_V"] =
                  unelgee2Scores["_V"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_VI"] =
                  unelgee2Scores["_VI"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_VII"] =
                  unelgee2Scores["_VII"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_VIII"] =
                  unelgee2Scores["_VIII"];
                lesson.ref.update(tmp);
              }
            });
        } else if (
          lesson.esisLessonType &&
          lesson.esisLessonType.esisLessonTypeId > 1 &&
          lesson.subLessons &&
          lesson.subLessons.length > 0
        ) {
          var sumOfFinalScores = 0;
          var numbers = 0;
          for (const sll of lesson.subLessons) {
            sll.ref
              .collection("lesson-groups")
              .where("teacher.teacherId", "==", this.userData.id)
              .get()
              .then(async (docs) => {
                for (var i = 0; i < docs.docs.length; i++) {
                  let gg = docs.docs[i].data();
                  gg.ref = docs.docs[i].ref;
                  gg.id = docs.docs[i].id;
                  // gg.students = null;
                  // console.log(gg);
                  if (gg.groupIndex) {
                    await gg.ref
                      .collection("students")
                      .orderBy("FIRST_NAME", "asc")
                      .get()
                      .then((docs) => {
                        // gg.students = [];
                        for (var i = 0; i < docs.docs.length; i++) {
                          let student = docs.docs[i].data();
                          student.ref = docs.docs[i].ref;
                          student.id = docs.docs[i].id;
                          var ss = allScores.find(
                            (score) => score.PERSON_ID == student.PERSON_ID
                          );
                          if (ss) {
                            if (typeof ss.scoreFinal == "number") {
                              student.scoreData = ss;
                              if (ss.scoreFinal >= 90) {
                                unelgee2Scores["_VIII"]++;
                              } else if (ss.scoreFinal >= 80) {
                                unelgee2Scores["_VII"]++;
                              } else if (ss.scoreFinal >= 70) {
                                unelgee2Scores["_VI"]++;
                              } else if (ss.scoreFinal >= 60) {
                                unelgee2Scores["_V"]++;
                              } else if (ss.scoreFinal >= 50) {
                                unelgee2Scores["_IV"]++;
                              } else if (ss.scoreFinal >= 40) {
                                unelgee2Scores["_III"]++;
                              } else if (ss.scoreFinal >= 30) {
                                unelgee2Scores["_II"]++;
                              } else if (ss.scoreFinal >= 0) {
                                unelgee2Scores["_I"]++;
                              }
                              if (
                                lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
                                lesson.courseInfo.GRADING_SCHEME_ID == 14
                              ) {
                                if (student.scoreData.scoreFinal > 0) {
                                  sumOfFinalScores = sumOfFinalScores + 1;
                                  numbers++;
                                }
                                console.log(
                                  student.FIRST_NAME,
                                  student.scoreData.scoreFinal
                                );
                              } else {
                                if (student.scoreData.scoreFinal >= 0) {
                                  sumOfFinalScores =
                                    sumOfFinalScores +
                                    student.scoreData.scoreFinal;
                                  numbers++;
                                }
                              }
                            }
                          }
                        }
                      });
                  }
                }
              });
          }
          var xxx = lesson["year1UnelgeeScores-" + this.userData.PERSON_ID];
          // console.log(sumOfFinalScores, numbers, sumOfFinalScores / numbers);
          if (
            lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
            lesson.courseInfo.GRADING_SCHEME_ID == 14
          ) {
            if (numbers > 0)
              xxx["scoreAverage"] = (sumOfFinalScores / numbers) * 100;
            else xxx["scoreAverage"] = 0;
          } else {
            if (numbers > 0) {
              xxx["scoreAverage"] = parseFloat(
                (sumOfFinalScores / numbers).toFixed(2)
              );
            } else xxx["scoreAverage"] = 0;
          }
          xxx["sumOfFinalScores"] = sumOfFinalScores;
          if (typeof xxx["scoreAverage"] == "number") {
            var tmp = {};
            tmp["year1UnelgeeScores-" + this.userData.id] = xxx;
            tmp["year1UnelgeeScores-" + this.userData.id]["_I"] =
              unelgee2Scores["_I"];
            tmp["year1UnelgeeScores-" + this.userData.id]["_II"] =
              unelgee2Scores["_II"];
            tmp["year1UnelgeeScores-" + this.userData.id]["_III"] =
              unelgee2Scores["_III"];
            tmp["year1UnelgeeScores-" + this.userData.id]["_IV"] =
              unelgee2Scores["_IV"];
            tmp["year1UnelgeeScores-" + this.userData.id]["_V"] =
              unelgee2Scores["_V"];
            tmp["year1UnelgeeScores-" + this.userData.id]["_VI"] =
              unelgee2Scores["_VI"];
            tmp["year1UnelgeeScores-" + this.userData.id]["_VII"] =
              unelgee2Scores["_VII"];
            tmp["year1UnelgeeScores-" + this.userData.id]["_VIII"] =
              unelgee2Scores["_VIII"];
            lesson.ref.update(tmp);
          }
          this.forceRerender();
          // this.loading = false;
          // this.unelgeeDialog = !this.unelgeeDialog;
        } else {
          lesson.ref
            .collection("lesson-groups")
            .where("teacher.teacherId", "==", this.userData.id)
            .get()
            .then(async (docs) => {
              var sumOfFinalScores = 0;
              var numbers = 0;
              // lesson.lessonGroups = [];
              for (var i = 0; i < docs.docs.length; i++) {
                let gg = docs.docs[i].data();
                gg.ref = docs.docs[i].ref;
                gg.id = docs.docs[i].id;
                // gg.students = [];
                if (gg.groupIndex) {
                  if (gg.allStudentsSelected) {
                    this.userData.school.ref
                      .collection(
                        "students-" + this.userData.school.currentYear
                      )
                      .where(
                        "classGroup-" + this.userData.school.currentYear,
                        "==",
                        lesson.classGroupRefs[0]
                      )
                      .orderBy("FIRST_NAME", "asc")
                      .get()
                      .then((docs) => {
                        for (var i = 0; i < docs.docs.length; i++) {
                          let student = docs.docs[i].data();
                          student.ref = docs.docs[i].ref;
                          student.id = docs.docs[i].id;
                          if (!student.moved) {
                            var ss = allScores.find(
                              (score) => score.PERSON_ID == student.PERSON_ID
                            );
                            if (ss) {
                              if (typeof ss.scoreFinal == "number") {
                                student.scoreData = ss;
                                if (ss.scoreFinal >= 90) {
                                  unelgee2Scores["_VIII"]++;
                                } else if (ss.scoreFinal >= 80) {
                                  unelgee2Scores["_VII"]++;
                                } else if (ss.scoreFinal >= 70) {
                                  unelgee2Scores["_VI"]++;
                                } else if (ss.scoreFinal >= 60) {
                                  unelgee2Scores["_V"]++;
                                } else if (ss.scoreFinal >= 50) {
                                  unelgee2Scores["_IV"]++;
                                } else if (ss.scoreFinal >= 40) {
                                  unelgee2Scores["_III"]++;
                                } else if (ss.scoreFinal >= 30) {
                                  unelgee2Scores["_II"]++;
                                } else if (ss.scoreFinal >= 0) {
                                  unelgee2Scores["_I"]++;
                                }
                                if (
                                  lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
                                  lesson.courseInfo.GRADING_SCHEME_ID == 14
                                ) {
                                  if (student.scoreData.scoreFinal > 0) {
                                    sumOfFinalScores = sumOfFinalScores + 1;
                                    numbers++;
                                  }
                                  console.log(
                                    student.FIRST_NAME,
                                    student.scoreData.scoreFinal
                                  );
                                } else {
                                  if (student.scoreData.scoreFinal >= 0) {
                                    sumOfFinalScores =
                                      sumOfFinalScores +
                                      student.scoreData.scoreFinal;
                                    numbers++;
                                  }
                                }
                              }
                            }
                          }
                        }
                      });
                  } else {
                    var zz = await gg.ref
                      .collection("students")
                      .orderBy("FIRST_NAME", "asc")
                      .get();
                    for (var j = 0; j < zz.docs.length; j++) {
                      let student = zz.docs[j].data();
                      student.ref = zz.docs[j].ref;
                      student.id = zz.docs[j].id;
                      // student.lessonGroup = gg;
                      // gg.students.push(student);
                      // this.students.push(student);

                      var ss = allScores.find(
                        (score) => score.PERSON_ID == student.PERSON_ID
                      );
                      if (ss) {
                        if (typeof ss.scoreFinal == "number") {
                          student.scoreData = ss;
                          if (ss.scoreFinal >= 90) {
                            unelgee2Scores["_VIII"]++;
                          } else if (ss.scoreFinal >= 80) {
                            unelgee2Scores["_VII"]++;
                          } else if (ss.scoreFinal >= 70) {
                            unelgee2Scores["_VI"]++;
                          } else if (ss.scoreFinal >= 60) {
                            unelgee2Scores["_V"]++;
                          } else if (ss.scoreFinal >= 50) {
                            unelgee2Scores["_IV"]++;
                          } else if (ss.scoreFinal >= 40) {
                            unelgee2Scores["_III"]++;
                          } else if (ss.scoreFinal >= 30) {
                            unelgee2Scores["_II"]++;
                          } else if (ss.scoreFinal >= 0) {
                            unelgee2Scores["_I"]++;
                          }
                          if (
                            lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
                            lesson.courseInfo.GRADING_SCHEME_ID == 14
                          ) {
                            if (student.scoreData.scoreFinal > 0) {
                              sumOfFinalScores = sumOfFinalScores + 1;
                              numbers++;
                            }
                            console.log(
                              student.FIRST_NAME,
                              student.scoreData.scoreFinal
                            );
                          } else {
                            if (student.scoreData.scoreFinal >= 0) {
                              sumOfFinalScores =
                                sumOfFinalScores + student.scoreData.scoreFinal;
                              numbers++;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              var xxx = lesson["year1UnelgeeScores-" + this.userData.PERSON_ID];
              if (
                lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
                lesson.courseInfo.GRADING_SCHEME_ID == 14
              ) {
                if (numbers > 0)
                  xxx["scoreAverage"] = (sumOfFinalScores / numbers) * 100;
                else xxx["scoreAverage"] = 0;
              } else {
                if (numbers > 0)
                  xxx["scoreAverage"] = parseFloat(
                    (sumOfFinalScores / numbers).toFixed(2)
                  );
                else xxx["scoreAverage"] = 0;
              }
              xxx["sumOfFinalScores"] = sumOfFinalScores;
              if (typeof xxx["scoreAverage"] == "number") {
                var tmp = {};
                tmp["year1UnelgeeScores-" + this.userData.id] = xxx;
                tmp["year1UnelgeeScores-" + this.userData.id]["_I"] =
                  unelgee2Scores["_I"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_II"] =
                  unelgee2Scores["_II"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_III"] =
                  unelgee2Scores["_III"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_IV"] =
                  unelgee2Scores["_IV"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_V"] =
                  unelgee2Scores["_V"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_VI"] =
                  unelgee2Scores["_VI"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_VII"] =
                  unelgee2Scores["_VII"];
                tmp["year1UnelgeeScores-" + this.userData.id]["_VIII"] =
                  unelgee2Scores["_VIII"];

                lesson.ref.update(tmp);
              }
              this.forceRerender();
            });
        }
      }
    },
    _recalculateAverageScores() {
      for (const lesson of this.filteredLessons) {
        this._calculateTeacherExecution(lesson);
      }
      if (
        this.unelgee2StatisticsOfTeacher &&
        this.unelgee2StatisticsOfTeacher.ref
      ) {
        this.unelgee2StatisticsOfTeacher.ref.update({
          scoreAllCalculated: true,
          scoreAllCalculatedAt: new Date(),
          finalized: false,
        });
      } else {
        this.userData.ref
          .collection("unelgee2")
          .doc(this.userData.school.currentYear + "-1")
          .set(
            {
              scoreAllCalculated: true,
              scoreAllCalculatedAt: new Date(),
              finalized: false,
            },
            { merge: true }
          );
      }
    },

    _getConfirmedProcent(item, teacherId) {
      var xx = (
        ((item["year1UnelgeeScores-" + teacherId]
          ? item["year1UnelgeeScores-" + teacherId].approvedNumber
          : 0) /
          (item["numberOfStudents-" + teacherId]
            ? item["numberOfStudents-" + teacherId]
            : 0) >
        0
          ? (item["year1UnelgeeScores-" + teacherId]
              ? item["year1UnelgeeScores-" + teacherId].approvedNumber
              : 0) /
            (item["numberOfStudents-" + teacherId]
              ? item["numberOfStudents-" + teacherId]
              : 0)
          : 0) * 100
      ).toFixed(0);
      return xx;
    },
    _getFinalExecution(lesson) {
      var x1 = this._getSumOfAllScored(lesson);
      var x2 = this._getMaxScored(lesson);
      if (x2) return (x1 / x2) * 100;
      else return null;
    },
    _getMaxScored(lesson) {
      var zz = lesson["numberOfStudents-" + this.userData.id];
      if (zz) return zz * 100;
      return null;
    },

    _getSumOfAllScored(lesson) {
      if (
        lesson["year1UnelgeeScores-" + this.userData.id] &&
        lesson["year1UnelgeeScores-" + this.userData.id].sumOfFinalScores !=
          undefined &&
        lesson["year1UnelgeeScores-" + this.userData.id].sumOfFinalScores >= 0
      )
        return lesson["year1UnelgeeScores-" + this.userData.id]
          .sumOfFinalScores;
      else return null;
    },
    _getAverageScoreOfLesson(lesson) {
      if (
        lesson["year1UnelgeeScores-" + this.userData.id] &&
        lesson["year1UnelgeeScores-" + this.userData.id].scoreAverage !=
          undefined &&
        lesson["year1UnelgeeScores-" + this.userData.id].scoreAverage >= 0
      )
        return lesson["year1UnelgeeScores-" + this.userData.id].scoreAverage;
      else return "-";
    },
    _checkLessons() {
      for (var i = 0; i < this.filteredLessons.length; i++) {
        let ll = this.filteredLessons[i];
        // if (
        //   !ll["year1UnelgeeScores-" + this.teacherId] ||
        //   (ll["year1UnelgeeScores-" + this.teacherId] &&
        //     ll["year1UnelgeeScores-" + this.teacherId].approvedNumber == 0 &&
        //     ll["year1UnelgeeScores-" + this.teacherId].rejectedNumber == 0 &&
        //     ll["year1UnelgeeScores-" + this.teacherId].inProgressNumber == 0)
        // ) {
        //   console.log(ll);
        // }
        var xx = ll["year1UnelgeeScores-" + this.teacherId];
        var zz = ll["numberOfStudents-" + this.teacherId] == xx.approvedNumber;
        if (xx && xx.inProgressNumber == 0 && !zz) {
          console.log(
            i + 1,
            ll.courseInfo.COURSE_NAME,
            this._getLessonClassGroupsName(ll),
            xx.inProgressNumber == 0,
            zz
          );
          this._checkScores(ll);
        }
      }
    },
    _checkScores(ll) {
      if (ll.subLessons && ll.subLessons.length > 0) {
        for (var subLesson of ll.subLessons) {
          fb.db
            .collection(
              this.userData.school.ref.path +
                "/unelgee2-" +
                this.userData.school.currentYear +
                "-scores" +
                "/" +
                subLesson.id +
                "/scores"
            )
            .where("lessonRef", "==", ll.ref)
            .get()
            .then((docs) => {
              docs.forEach((doc) => {
                let studentScore = doc.data();
                studentScore.id = doc.id;
                studentScore.ref = doc.ref;
                console.log(studentScore);
                // tobeCheckedScores.push(studentScore);
              });
            });
        }
      } else {
        var unelgeePath1 =
          this.userData.school.ref.path +
          "/unelgee2-" +
          this.userData.school.currentYear +
          "-scores" +
          "/" +
          ll.id;
        var unelgeePath2 = unelgeePath1 + "/scores";

        fb.db
          .collection(unelgeePath2)
          .where("lessonRef", "==", ll.ref)
          .where("createdByRef", "==", this.userData.ref)
          .get()
          .then((docs) => {
            // var counter = 0
            var numberSentToManager = 0;
            docs.forEach((doc) => {
              // counter++
              let studentScore = doc.data();
              studentScore.id = doc.id;
              studentScore.ref = doc.ref;
              console.log(studentScore);
              if (studentScore.sentToManager) {
                numberSentToManager++;
              }
              //console.log(counter,studentScore.FIRST_NAME, studentScore.scoreFinal, studentScore.sentToManager, studentScore.createdByName)
              // tobeCheckedScores.push(studentScore);
            });
            console.log(ll["year1UnelgeeScores-" + this.teacherId]);
            console.log(
              numberSentToManager,
              ll["year1UnelgeeScores-" + this.teacherId]
                ? ll["year1UnelgeeScores-" + this.teacherId]["sentNumber"]
                : -1
            );
          });
      }
    },

    _print3() {
      console.log(this.currentSelectedLesson);
    },
    _notifyProblem() {
      this.$swal({
        title: "Сурагч дутуу байна уу? ",
        text: "Үүнийг мэдэх бол ТИЙМ сонгоно уу! Таны мэдэгдлийг aваад бид шуурхай холбогдоно.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var ss = {
            notifiedBy: this.userData,
            notifiedAt: new Date(),
            issueFor: this.currentSelectedLesson.ref.path,
            classGroups: this.currentSelectedLesson.classGroups,
            esisLessonType: this.currentSelectedLesson.esisLessonType,
            courseInfo: this.currentSelectedLesson.courseInfo,
            issueName: "studentsFailed",
            schoolName: this.userData.school.name,
            techerFirstName: this.userData.firstName
              ? this.userData.firstName
              : null,
          };
          if (this.currentSelectedLesson.lessonGroups) {
            ss["lessonGroups"] = this.currentSelectedLesson.lessonGroups.map(
              (mm) => {
                return {
                  allStudentsSelected: mm.allStudentsSelected,
                  groupIndex: mm.groupIndex,
                  name: mm.name,
                  ref: mm.ref,
                };
              }
            );
          }
          console.log(ss);
          fb.db
            .collection("_problemsUnelgee2")
            .doc()
            .set(ss)
            .then(() => {
              this.$swal.fire("Мэдэгдэл xүлээлж авлаа!");
            });
        }
      });
    },
    getClassStudentNumber(item) {
      if (item.esisLessonType && item.esisLessonType.esisLessonTypeId == 1) {
        item.isGroupBased = false;
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where(
            "classGroup-" + this.userData.school.currentYear,
            "==",
            item.classGroupRefs[0]
          )
          .orderBy("FIRST_NAME", "asc")
          .get()
          .then((docs) => {
            var numberOfStudents = 0;
            var loadingCounter = 0;
            docs.forEach((doc) => {
              if (doc.exists && doc.data().moved != true) {
                numberOfStudents++;
              }
              loadingCounter++;
              if (loadingCounter == docs.size) {
                if (
                  item["numberOfStudents-" + this.userData.id] == undefined ||
                  item["numberOfStudents-" + this.userData.id] !=
                    numberOfStudents
                ) {
                  var tmp = {};
                  tmp["numberOfStudents-" + this.userData.id] =
                    numberOfStudents;
                  item.ref.update(tmp).then(() => {
                    item["numberOfStudents-" + this.userData.id] =
                      numberOfStudents;
                    this.$forceUpdate();
                  });
                }
              }
            });
          });
      } else if (
        item.esisLessonType &&
        item.esisLessonType.esisLessonTypeId > 1 &&
        item.subLessons &&
        item.subLessons.length > 0
      ) {
        // console.log(item.courseInfo.COURSE_NAME);
        var numberOfStudents = 0;
        var slLoadingCounter = 0;
        for (const sll of item.subLessons) {
          sll.ref
            .collection("lesson-groups")
            .where("teacher.teacherId", "==", this.userData.id)
            .get()
            .then((docs) => {
              var loadingCounter = 0;
              docs.forEach(async (doc) => {
                await doc.ref
                  .collection("students")
                  .get()
                  .then((docs) => {
                    numberOfStudents = numberOfStudents + docs.size;
                    loadingCounter++;
                  });
                if (loadingCounter == docs.size) {
                  slLoadingCounter++;
                }
              });
              if (slLoadingCounter == docs.size) {
                if (
                  item["numberOfStudents-" + this.userData.id] == undefined ||
                  item["numberOfStudents-" + this.userData.id] !=
                    numberOfStudents
                ) {
                  var tmp = {};
                  tmp["numberOfStudents-" + this.userData.id] =
                    numberOfStudents;
                  item.ref.update(tmp).then(() => {
                    item["numberOfStudents-" + this.userData.id] =
                      numberOfStudents;
                    this.$forceUpdate();
                  });
                }
              }
            });
        }
      } else {
        item.ref
          .collection("lesson-groups")
          .where("teacher.teacherId", "==", this.userData.id)
          .where("groupIndex", ">=", 0)
          .get()
          .then((docs) => {
            var numberOfStudents2 = 0;
            var loadingCounter = 0;
            docs.forEach(async (doc) => {
              if (doc.data().allStudentsSelected) {
                await this.userData.school.ref
                  .collection("students-" + this.userData.school.currentYear)
                  .where(
                    "classGroup-" + this.userData.school.currentYear,
                    "==",
                    item.classGroupRefs[0]
                  )
                  .orderBy("FIRST_NAME", "asc")
                  .get()
                  .then((docs) => {
                    numberOfStudents2 = numberOfStudents2 + docs.size;
                    loadingCounter++;
                  });
              } else {
                await doc.ref
                  .collection("students")
                  .get()
                  .then((docs) => {
                    numberOfStudents2 = numberOfStudents2 + docs.size;
                    loadingCounter++;
                  });
              }
              if (loadingCounter == docs.size) {
                // console.log(numberOfStudents2, item.courseInfo.COURSE_NAME);
                if (
                  item["numberOfStudents-" + this.userData.id] == undefined ||
                  item["numberOfStudents-" + this.userData.id] !=
                    numberOfStudents2
                ) {
                  var tmp = {};
                  tmp["numberOfStudents-" + this.userData.id] =
                    numberOfStudents2;
                  item.ref.update(tmp).then(() => {
                    item["numberOfStudents-" + this.userData.id] =
                      numberOfStudents2;
                    this.$forceUpdate();
                  });
                }
              }
            });
          });
      }
    },
    getLessonScoreAnalytics(item) {
      if (this.filteredLessons && this.filteredLessons.length > 0) {
        // if (
        //   status ||
        //   !item["numberOfStudents-" + this.userData.id] ||
        //   !item["year1UnelgeeScores-" + this.userData.id] ||
        //   (item["year1UnelgeeScores-" + this.userData.id] &&
        //     item["year1UnelgeeScores-" + this.userData.id].approvedNumber !=
        //       item["numberOfStudents-" + this.userData.id])
        // ) {
        if (item.subLessons && item.subLessons.length > 0) {
          var approvedNumber = 0;
          var inProgressNumber = 0;
          var rejectedNumber = 0;
          var sentNumber = 0;
          var slLoadingCounter = 0;
          item.subLessons.forEach((subLesson) => {
            var query = this.userData.school.ref
              .collection(
                "unelgee2-" + this.userData.school.currentYear + "-scores"
              )
              .doc(String(subLesson.id))
              .collection("scores");

            // if (item.esisLessonType.esisLessonTypeId > 1)
            query = query.where("gradedBy.id", "==", this.userData.id);

            query.get().then((docs) => {
              var loadingCounter = 0;
              docs.forEach(async (doc) => {
                let score = doc.data();
                score.ref = doc.ref;
                score.id = doc.id;

                var studentDoc = await this.userData.school.ref
                  .collection("students-" + this.userData.school.currentYear)
                  .doc(String(score.PERSON_ID))
                  .get();

                if (studentDoc.exists && studentDoc.data().moved != true) {
                  if (score.approved == true) {
                    approvedNumber++;
                  } else if (score.approved == false) {
                    rejectedNumber++;
                  } else if (score.sentToManager == true) {
                    inProgressNumber++;
                  }
                  if (
                    score.sentToManager != true &&
                    score.score != null &&
                    score.score != undefined &&
                    typeof score.score == "number"
                  ) {
                    sentNumber++;
                  }
                }
                loadingCounter++;
                if (loadingCounter == docs.size) {
                  slLoadingCounter++;
                }
              });
              if (slLoadingCounter == item.subLessons.length) {
                var year1UnelgeeScores = {
                  approvedNumber: approvedNumber,
                  inProgressNumber: inProgressNumber,
                  rejectedNumber: rejectedNumber,
                  sentNumber: sentNumber,
                };
                var tmp = {};
                tmp["year1UnelgeeScores-" + this.userData.id] =
                  year1UnelgeeScores;
                item.ref.update(tmp).then(() => {
                  console.log("yyy");
                  item["year1UnelgeeScores-" + this.userData.id] =
                    year1UnelgeeScores;
                  this.$forceUpdate();
                });
              }
            });
          });
        } else {
          if (item.byTeachers.length > 1) {
            var query2 = this.userData.school.ref
              .collection(
                "unelgee2-" + this.userData.school.currentYear + "-scores"
              )
              .doc(String(item.id))
              .collection("scores");

            if (item.esisLessonType.esisLessonTypeId > 1)
              query2 = query2.where("gradedBy.id", "==", this.userData.id);

            query2.get().then((docs) => {
              var approvedNumber = 0;
              var inProgressNumber = 0;
              var rejectedNumber = 0;
              var sentNumber = 0;
              var loadingCounter = 0;
              docs.forEach(async (doc) => {
                let score = doc.data();
                score.ref = doc.ref;
                score.id = doc.id;
                if (score.lessonGroupRef) {
                  var studentDoc = await this.userData.school.ref
                    .collection("students-" + this.userData.school.currentYear)
                    .doc(String(score.PERSON_ID))
                    .get();

                  if (studentDoc.exists && studentDoc.data().moved != true) {
                    await score.lessonGroupRef.get().then((doc) => {
                      if (
                        doc.exists &&
                        doc.data().teacher &&
                        doc.data().teacher.teacherId == this.userData.id
                      ) {
                        if (score.approved == true) {
                          approvedNumber++;
                        } else if (score.approved == false) {
                          rejectedNumber++;
                        } else if (score.sentToManager == true) {
                          inProgressNumber++;
                        }
                        if (
                          score.sentToManager != true &&
                          score.score != null &&
                          score.score != undefined &&
                          typeof score.score == "number"
                        ) {
                          sentNumber++;
                        }
                      }
                      loadingCounter++;
                    });
                  } else {
                    loadingCounter++;
                  }
                } else {
                  loadingCounter++;
                }
                if (loadingCounter == docs.size) {
                  var year1UnelgeeScores = {
                    approvedNumber: approvedNumber,
                    inProgressNumber: inProgressNumber,
                    rejectedNumber: rejectedNumber,
                    sentNumber: sentNumber,
                  };

                  var tmp = {};
                  tmp["year1UnelgeeScores-" + this.userData.id] =
                    year1UnelgeeScores;
                  item.ref.update(tmp).then(() => {
                    console.log("yyy");
                    item["year1UnelgeeScores-" + this.userData.id] =
                      year1UnelgeeScores;
                    this.$forceUpdate();
                  });
                }
              });
            });
          } else {
            var query3 = this.userData.school.ref
              .collection(
                "unelgee2-" + this.userData.school.currentYear + "-scores"
              )
              .doc(String(item.id))
              .collection("scores");
            query3.get().then((docs) => {
              var approvedNumber = 0;
              var inProgressNumber = 0;
              var rejectedNumber = 0;
              var sentNumber = 0;
              var loadingCounter = 0;

              docs.forEach(async (doc) => {
                let score = doc.data();
                score.ref = doc.ref;
                score.id = doc.id;

                var studentDoc = await this.userData.school.ref
                  .collection("students-" + this.userData.school.currentYear)
                  .doc(String(score.PERSON_ID))
                  .get();

                if (studentDoc.exists && studentDoc.data().moved != true) {
                  if (score.approved == true) {
                    approvedNumber++;
                  } else if (score.approved == false) {
                    rejectedNumber++;
                  } else if (score.sentToManager == true) {
                    inProgressNumber++;
                  }

                  if (
                    score.sentToManager != true &&
                    score.score != null &&
                    score.score != undefined &&
                    typeof score.score == "number"
                  ) {
                    sentNumber++;
                  }
                }
                loadingCounter++;
                if (loadingCounter == docs.size) {
                  var year1UnelgeeScores = {
                    approvedNumber: approvedNumber,
                    inProgressNumber: inProgressNumber,
                    rejectedNumber: rejectedNumber,
                    sentNumber: sentNumber,
                  };
                  console.log(year1UnelgeeScores);
                  var tmp = {};
                  tmp["year1UnelgeeScores-" + this.userData.id] =
                    year1UnelgeeScores;
                  item.ref.update(tmp).then(() => {
                    console.log("yyy");
                    item["year1UnelgeeScores-" + this.userData.id] =
                      year1UnelgeeScores;
                    this.$forceUpdate();
                  });
                }
              });
            });
          }
        }
        // }
      }
    },
    recalculateLessonAnalysisNumber() {
      this.$swal({
        title: "Даxин илгээx үү?",
        text: "Xэрэв менежер xүлээж аваагүй гэсэн асуудал гарвал даxин илгээxэд асуудалгүй болно.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var approvedNumber = 0;
          var inProgressNumber = 0;
          var rejectedNumber = 0;
          var sentNumber = 0;
          var scoreAverage = 0;
          for (var student of this.students) {
            if (
              student &&
              student.score != null &&
              student.score != undefined &&
              typeof student.score == "number"
            ) {
              // console.log(student);
              if (student.approved == true) {
                scoreAverage = scoreAverage + student.score;

                approvedNumber++;
              } else if (student.approved == false) {
                rejectedNumber++;
              } else if (student.sentToManager == true) {
                inProgressNumber++;
              } else if (
                student.sentToManager != true &&
                student.score != null &&
                student.score != undefined &&
                typeof student.score == "number"
              ) {
                sentNumber++;
              }
            }
          }

          var tmp = {};
          tmp["year1UnelgeeScores"] = {
            approvedNumber: 0,
            rejectedNumber: 0,
            inProgressNumber: 0,
            sentNumber: 0,
          };

          tmp["year1UnelgeeScores-" + this.userData.id] = {
            approvedNumber: approvedNumber,
            rejectedNumber: rejectedNumber,
            inProgressNumber: inProgressNumber,
            sentNumber: sentNumber,
            scoreAverage: (scoreAverage / approvedNumber).toFixed(2),
          };

          this.currentSelectedLesson.byTeachers.forEach((teacher) => {
            if (
              this.currentSelectedLesson[
                "year1UnelgeeScores-" + teacher.teacherId
              ]
            ) {
              if (teacher.teacherId == this.userData.id) {
                tmp["year1UnelgeeScores"].approvedNumber =
                  tmp["year1UnelgeeScores"].approvedNumber +
                  tmp["year1UnelgeeScores-" + teacher.teacherId].approvedNumber;
                tmp["year1UnelgeeScores"].rejectedNumber =
                  tmp["year1UnelgeeScores"].rejectedNumber +
                  tmp["year1UnelgeeScores-" + teacher.teacherId].rejectedNumber;
                tmp["year1UnelgeeScores"].inProgressNumber =
                  tmp["year1UnelgeeScores"].inProgressNumber +
                  tmp["year1UnelgeeScores-" + teacher.teacherId]
                    .inProgressNumber;
                tmp["year1UnelgeeScores"].sentNumber =
                  tmp["year1UnelgeeScores"].sentNumber +
                  tmp["year1UnelgeeScores-" + teacher.teacherId].sentNumber;
              } else {
                tmp["year1UnelgeeScores"].approvedNumber =
                  tmp["year1UnelgeeScores"].approvedNumber +
                  this.currentSelectedLesson[
                    "year1UnelgeeScores-" + teacher.teacherId
                  ].approvedNumber;
                tmp["year1UnelgeeScores"].rejectedNumber =
                  tmp["year1UnelgeeScores"].rejectedNumber +
                  this.currentSelectedLesson[
                    "year1UnelgeeScores-" + teacher.teacherId
                  ].rejectedNumber;
                tmp["year1UnelgeeScores"].inProgressNumber =
                  tmp["year1UnelgeeScores"].inProgressNumber +
                  this.currentSelectedLesson[
                    "year1UnelgeeScores-" + teacher.teacherId
                  ].inProgressNumber;
                tmp["year1UnelgeeScores"].sentNumber =
                  tmp["year1UnelgeeScores"].sentNumber +
                  this.currentSelectedLesson[
                    "year1UnelgeeScores-" + teacher.teacherId
                  ].sentNumber;
              }
            }
          });
          console.log(tmp);
          this.currentSelectedLesson.ref.update(tmp).then(() => {
            for (var student of this.students) {
              student.score = null;
              student.scoreFinal = null;
            }
            this._closeUnelgeeDialog();
            this.$swal.fire("Амжилттай xадгалагдсан!");
          });
        }
      });
    },
    _saveUnelgee2OfStudents() {
      var numberOfScoredStudents = 0;

      var unelgeePath1 =
        this.userData.school.ref.path +
        "/unelgee2-" +
        this.userData.school.currentYear +
        "-scores" +
        "/" +
        this.currentSelectedLesson.id;
      var unelgeePath2 = unelgeePath1 + "/scores";

      var batch = fb.db.batch();
      //var batchStudent = fb.db.batch()
      this.savingUnelgee = true;

      var approvedNumber = 0;
      var inProgressNumber = 0;
      var rejectedNumber = 0;
      var sentNumber = 0;
      var scoreAverage = 0;

      var worked = false;
      for (var student of this.students) {
        if (
          student &&
          student.score != null &&
          student.score != undefined &&
          typeof student.score == "number"
        ) {
          var scoreUnelgee = Object.assign({}, student);
          if (student.lessonGroup) {
            if (
              unelgeePath1.includes(String(student.STUDENT_GROUP_ID)) == false
            ) {
              unelgeePath1 =
                this.userData.school.ref.path +
                "/unelgee2-" +
                this.userData.school.currentYear +
                "-scores" +
                "/" +
                student.STUDENT_GROUP_ID +
                "-" +
                this.currentSelectedLesson.courseInfo.COURSE_ID;
              unelgeePath2 = unelgeePath1 + "/scores";
            }
            scoreUnelgee["lessonGroupId"] = student.lessonGroup.id;
            scoreUnelgee["lessonGroupIndex"] = student.lessonGroup.groupIndex;
            scoreUnelgee["lessonGroupRef"] = student.lessonGroup.ref;
          }
          scoreUnelgee["lessonGroup"] = null;
          scoreUnelgee["schoolId"] = this.userData.school.id;
          scoreUnelgee["schoolName"] = this.userData.school.name;
          scoreUnelgee["createdByRef"] = this.userData.ref;
          scoreUnelgee["createdByName"] = this.userData["DISPLAY_NAME"]
            ? this.userData["DISPLAY_NAME"]
            : null;
          scoreUnelgee["lesson"] = null;
          scoreUnelgee["lessonId"] = this.currentSelectedLesson.id;
          scoreUnelgee["lessonRef"] = this.currentSelectedLesson.ref;
          scoreUnelgee["courseInfo"] = this.currentSelectedLesson.courseInfo;
          scoreUnelgee["semester"] = 2;
          scoreUnelgee["year1"] = 1;
          scoreUnelgee["year"] = this.userData.school.currentYear;

          scoreUnelgee["createdAt"] = new Date();
          scoreUnelgee["updatedAt"] = new Date();

          // scoreUnelgee["score"] = scoreUnelgee["score"]
          // scoreUnelgee["score"] = scoreUnelgee["score"]
          // scoreUnelgee["description"] = scoreUnelgee["unelgee2"]["description"]

          scoreUnelgee["unelgee2"] = null;

          // if (unelgeePath2.score) console.log(unelgeePath2);
          // if (scoreUnelgee.scoreFinal) console.log(scoreUnelgee);
          if (student.unelgee1Scores) {
            scoreUnelgee["unelgee1Scores"] = student.unelgee1Scores;
          }
          if (student.averageScore) {
            scoreUnelgee["averageScore"] = student.averageScore;
          }
          if (student.highestScore) {
            scoreUnelgee["highestScore"] = student.highestScore;
            scoreUnelgee["highestScoreExam"] = student.highestScoreExam;
          }
          if (student.latestScore) {
            scoreUnelgee["latestScore"] = student.latestScore;
            scoreUnelgee["latestScoreExam"] = student.latestScoreExam;
          }
          if (student.scoreBasedOnState) {
            scoreUnelgee["scoreBasedOnState"] = student.scoreBasedOnState;
          }
          if (student.scoreBasedOnExam) {
            scoreUnelgee["scoreBasedOnExam"] = student.scoreBasedOnExam;
          }

          scoreUnelgee["gradedBy"] = this.userData;

          // var unelgeeStudentPath = student.ref.path+"/"+"student-courses-"+this.userData.school.currentYear
          // + "/"+this.currentSelectedLesson.courseInfo.COURSE_ID
          // +"/student-unelgee1-"+this.userData.school.currentYear
          if (student.modifiedScore) {
            if (
              scoreUnelgee.score != null &&
              scoreUnelgee.score != undefined &&
              typeof scoreUnelgee.score == "number"
            ) {
              if (
                student.changedScore &&
                student.scoreState != null &&
                student.scoreState != -1
              ) {
                scoreUnelgee["sentToManager"] = false;
                batch.set(
                  fb.db
                    .collection(unelgeePath2)
                    .doc(
                      this.currentSelectedLesson.esisLessonType
                        .esisLessonTypeId > 1
                        ? student.id + "-" + student.lessonGroup.id
                        : student.id
                    ),
                  {
                    approved: fb.firestore.FieldValue.delete(),
                    approvedAt: fb.firestore.FieldValue.delete(),
                    approvedByRef: fb.firestore.FieldValue.delete(),
                  },
                  { merge: true }
                );
              }

              numberOfScoredStudents++;
              // var docName1=this.currentSelectedExam.id+"-"+student.id
              // if(this.currentSelectedLesson.isGroupBased){
              //   docName1 = docName1 + "-"+student.lessonGroup.id
              // }
              batch.set(
                fb.db
                  .collection(unelgeePath2)
                  .doc(
                    this.currentSelectedLesson.esisLessonType.esisLessonTypeId >
                      1
                      ? student.id + "-" + student.lessonGroup.id
                      : student.id
                  ),

                scoreUnelgee,
                { merge: true }
              );
            }

            // batch.set(fb.db.collection(unelgeeStudentPath).doc(docName2), scoreUnelgee,{merge:true})
          }
          if (scoreUnelgee.approved == true) {
            approvedNumber++;
            scoreAverage = scoreAverage + scoreUnelgee.score;
            worked = true;
          } else if (scoreUnelgee.approved == false) {
            rejectedNumber++;
            worked = true;
          } else if (scoreUnelgee.sentToManager == true) {
            inProgressNumber++;
            worked = true;
          } else if (
            scoreUnelgee.sentToManager != true &&
            scoreUnelgee.score != null &&
            scoreUnelgee.score != undefined &&
            typeof scoreUnelgee.score == "number"
          ) {
            worked = true;
            sentNumber++;
          }
        }
      }

      var tmp = {};
      tmp["year1UnelgeeScores"] = {
        approvedNumber: 0,
        rejectedNumber: 0,
        inProgressNumber: 0,
        sentNumber: 0,
      };

      tmp["year1UnelgeeScores-" + this.userData.id] = {
        approvedNumber: approvedNumber,
        rejectedNumber: rejectedNumber,
        inProgressNumber: inProgressNumber,
        sentNumber: sentNumber,
        scoreAverage: (scoreAverage / approvedNumber).toFixed(2),
      };
      tmp["sentToManager"] = worked;

      this.currentSelectedLesson.byTeachers.forEach((teacher) => {
        if (
          this.currentSelectedLesson["year1UnelgeeScores-" + teacher.teacherId]
        ) {
          if (teacher.teacherId == this.userData.id) {
            tmp["year1UnelgeeScores"].approvedNumber =
              tmp["year1UnelgeeScores"].approvedNumber +
              tmp["year1UnelgeeScores-" + teacher.teacherId].approvedNumber;
            tmp["year1UnelgeeScores"].rejectedNumber =
              tmp["year1UnelgeeScores"].rejectedNumber +
              tmp["year1UnelgeeScores-" + teacher.teacherId].rejectedNumber;
            tmp["year1UnelgeeScores"].inProgressNumber =
              tmp["year1UnelgeeScores"].inProgressNumber +
              tmp["year1UnelgeeScores-" + teacher.teacherId].inProgressNumber;
            tmp["year1UnelgeeScores"].sentNumber =
              tmp["year1UnelgeeScores"].sentNumber +
              tmp["year1UnelgeeScores-" + teacher.teacherId].sentNumber;
          } else {
            tmp["year1UnelgeeScores"].approvedNumber =
              tmp["year1UnelgeeScores"].approvedNumber +
              this.currentSelectedLesson[
                "year1UnelgeeScores-" + teacher.teacherId
              ].approvedNumber;
            tmp["year1UnelgeeScores"].rejectedNumber =
              tmp["year1UnelgeeScores"].rejectedNumber +
              this.currentSelectedLesson[
                "year1UnelgeeScores-" + teacher.teacherId
              ].rejectedNumber;
            tmp["year1UnelgeeScores"].inProgressNumber =
              tmp["year1UnelgeeScores"].inProgressNumber +
              this.currentSelectedLesson[
                "year1UnelgeeScores-" + teacher.teacherId
              ].inProgressNumber;
            tmp["year1UnelgeeScores"].sentNumber =
              tmp["year1UnelgeeScores"].sentNumber +
              this.currentSelectedLesson[
                "year1UnelgeeScores-" + teacher.teacherId
              ].sentNumber;
          }
        }
      });
      console.log(tmp);
      batch.set(this.currentSelectedLesson.ref, tmp, { merge: true });
      batch.set(
        fb.db.doc(unelgeePath1),
        {
          numberOfScoredStudents: numberOfScoredStudents,
          numberOfStudents: this.students.length,
          calculationModel: this.simpleCalculationModel,
          createdAt: new Date(),
        },
        { merge: true }
      );
      batch.commit().then(() => {
        this.savingUnelgee = false;
        for (var student of this.students) {
          student.score = null;
          student.scoreFinal = null;
        }
        var lesson = this.lessons.find(
          (lesson) => lesson.id == this.currentSelectedLesson.id
        );
        lesson["year1UnelgeeScores-" + this.userData.id] =
          tmp["year1UnelgeeScores-" + this.userData.id];
        this.$forceUpdate();
        this._closeUnelgeeDialog();
        this.$swal.fire("Амжилттай xадгалагдсан!");
        // this.getLessonScoreAnalytics(this.currentSelectedLesson);
      });

      console.log(numberOfScoredStudents);
      console.log(
        this.currentSelectedLesson.courseInfo.COURSE_ID,
        this.currentSelectedLesson.courseInfo
      );
    },
    _closeUnelgeeDialog() {
      this.currentSelectedLesson.loading2 = false;
      this.unelgeeDialog = !this.unelgeeDialog;
    },
    async _doUnelgee(item) {
      this.loading = true;
      this.currentSelectedLesson = item;
      this.currentSelectedLesson.loading2 = true;
      var unelgeePath1 =
        this.userData.school.ref.path +
        "/unelgee2-" +
        this.userData.school.currentYear +
        "-scores" +
        "/" +
        this.currentSelectedLesson.id;
      var unelgeePath2 = unelgeePath1 + "/scores";
      console.log(unelgeePath2);

      this.allScores = [];

      if (
        this.currentSelectedLesson.subLessons &&
        this.currentSelectedLesson.subLessons.length > 0
      ) {
        for (var subLesson of this.currentSelectedLesson.subLessons) {
          fb.db
            .collection(
              this.userData.school.ref.path +
                "/unelgee2-" +
                this.userData.school.currentYear +
                "-scores" +
                "/" +
                subLesson.id +
                "/scores"
            )
            .get()
            .then((docs) => {
              docs.forEach((doc) => {
                let studentScore = doc.data();
                studentScore.id = doc.id;
                studentScore.ref = doc.ref;
                this.allScores.push(studentScore);
              });
            });
        }
      } else {
        fb.db
          .collection(unelgeePath2)
          .get()
          .then((docs) => {
            docs.forEach((doc) => {
              let studentScore = doc.data();
              studentScore.id = doc.id;
              studentScore.ref = doc.ref;
              this.allScores.push(studentScore);
            });
          });
      }
      //reading unelgee 1
      this.userData.school.ref
        .collection("courses-" + this.userData.school.currentYear)
        .doc(this.currentSelectedLesson.courseInfo.COURSE_ID.toString())
        .collection("unelgee1-" + this.userData.school.currentYear + "-scores")
        // .where("STUDENT_GROUP_ID","in", [100003497414761])
        .onSnapshot((querySnapshot) => {
          this.unelgee1Scores = [];
          querySnapshot.forEach(async (doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            await item.examRef.get().then((doc) => {
              if (doc.exists && doc.data().deleted != true) {
                item.examDate = new Date(doc.data().examDate);
                item.deleted = false;
              } else {
                item.deleted = true;
              }
            });
            if (item.deleted == false) {
              this.unelgee1Scores.push(item);
            }
          });
        });
      // end of unelgee 1
      if (this.currentSelectedLesson) {
        if (
          this.currentSelectedLesson &&
          this.currentSelectedLesson.esisLessonType &&
          this.currentSelectedLesson.esisLessonType.esisLessonTypeId == 1
        ) {
          this.currentSelectedLesson.isGroupBased = false;
          this.userData.school.ref
            .collection("students-" + this.userData.school.currentYear)
            .where(
              "STUDENT_GROUP_ID",
              "==",
              Number(this.currentSelectedLesson.classGroupRefs[0].id)
            )
            .orderBy("FIRST_NAME", "asc")
            .get()
            .then((docs) => {
              console.log(docs.size);
              this.students = [];
              docs.forEach((doc) => {
                let student = doc.data();
                student.ref = doc.ref;
                student.id = doc.id;
                // console.log(student)
                if (!student.moved) {
                  this.students.push(student);
                }
              });
              this.loading = false;
              this.forceRerender();
              this.unelgeeDialog = !this.unelgeeDialog;
            });
        } else if (
          this.currentSelectedLesson.esisLessonType &&
          this.currentSelectedLesson.esisLessonType.esisLessonTypeId > 1 &&
          this.currentSelectedLesson.subLessons &&
          this.currentSelectedLesson.subLessons.length > 0
        ) {
          console.log("aaaa");
          this.students = [];
          this.currentSelectedLesson.isGroupBased = true;
          this.currentSelectedLesson.mixed = true;
          // this.selectedLessonGroups = [];
          for (const sll of this.currentSelectedLesson.subLessons) {
            sll.ref
              .collection("lesson-groups")
              .where("teacher.teacherId", "==", this.userData.id)
              .get()
              .then((docs) => {
                docs.forEach(async (doc) => {
                  let gg = doc.data();
                  gg.id = doc.id;
                  gg.ref = doc.ref;
                  gg.students = null;
                  // console.log(gg);
                  if (gg.groupIndex) {
                    await gg.ref
                      .collection("students")
                      .orderBy("FIRST_NAME", "asc")
                      .get()
                      .then((docs) => {
                        gg.students = [];
                        docs.forEach((doc) => {
                          let student = doc.data();
                          student.id = doc.id;
                          student.ref = doc.ref;
                          student.classGroup = sll.classGroups[0];
                          student.classGroupRef =
                            sll.classGroups[0].classGroupRef;
                          student.lessonGroup = gg;
                          student.attendances = null;
                          student.studentsNotes = null;
                          gg.students.push(student);
                          this.students.push(student);
                        });
                      });
                  }
                });
              });
          }
          this.loading = false;
          this.forceRerender();
          this.unelgeeDialog = !this.unelgeeDialog;
        } else {
          this.currentSelectedLesson.lessonGroups = null;
          this.students = [];
          this.currentSelectedLesson.isGroupBased = true;
          this.currentSelectedLesson.ref
            .collection("lesson-groups")
            .where("teacher.teacherId", "==", this.userData.id)
            .get()
            .then((docs) => {
              this.currentSelectedLesson.lessonGroups = [];
              docs.forEach(async (doc) => {
                let gg = doc.data();
                gg.id = doc.id;
                gg.ref = doc.ref;
                gg.students = [];
                if (gg.groupIndex) {
                  if (gg.allStudentsSelected) {
                    this.userData.school.ref
                      .collection(
                        "students-" + this.userData.school.currentYear
                      )
                      .where(
                        "STUDENT_GROUP_ID",
                        "==",
                        Number(this.currentSelectedLesson.classGroupRefs[0].id)
                      )
                      .orderBy("FIRST_NAME", "asc")
                      .get()
                      .then((docs) => {
                        docs.forEach((doc) => {
                          let student = doc.data();
                          student.ref = doc.ref;
                          student.id = doc.id;
                          student.lessonGroup = gg;
                          if (!student.moved) {
                            this.students.push(student);
                            gg.students.push(student);
                          }
                        });
                      });
                  } else {
                    var zz = await gg.ref
                      .collection("students")
                      .orderBy("FIRST_NAME", "asc")
                      .get();
                    zz.docs.forEach((doc) => {
                      let student = doc.data();
                      student.ref = doc.ref;
                      student.id = doc.id;
                      student.lessonGroup = gg;
                      gg.students.push(student);
                      this.students.push(student);
                    });
                  }
                }
                this.currentSelectedLesson.lessonGroups.push(gg);
                // this.forceRerender();
              });
              this.loading = false;
              this.forceRerender();
              this.unelgeeDialog = !this.unelgeeDialog;
            });
        }
      }
    },
    checkIfAllScoresExists() {
      var status = true;
      for (var student of this.students) {
        if (
          student.score == null ||
          student.score == undefined ||
          typeof student.score != "number"
        ) {
          status = false;
        }
      }
      console.log(status);
      return status;
    },
    async _sendUnelgeeToManager() {
      if (this.checkIfAllScoresExists() == true) {
        this.$swal({
          title: "Үнэлгээ 2-ийн дүнг Менежерт илгээх үү?",
          text: "Менежерийн баталгаажуулсан, цуцалсан мэдээлэл хяналтын самбарт харагдана!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            var numberOfScoredStudents = 0;
            var unelgeePath1 =
              this.userData.school.ref.path +
              "/unelgee2-" +
              this.userData.school.currentYear +
              "-scores" +
              "/" +
              this.currentSelectedLesson.id;
            var unelgeePath2 = unelgeePath1 + "/scores";
            var batch = fb.db.batch();
            //var batchStudent = fb.db.batch()
            this.savingUnelgee = true;

            var approvedNumber = 0;
            var inProgressNumber = 0;
            var rejectedNumber = 0;
            var sentNumber = 0;
            var scoreAverage = 0;

            var worked = false;
            for (var student of this.students) {
              if (
                student &&
                student.score != null &&
                student.score != undefined &&
                typeof student.score == "number"
              ) {
                var scoreUnelgee = Object.assign({}, student);
                if (student.lessonGroup) {
                  if (
                    unelgeePath1.includes(String(student.STUDENT_GROUP_ID)) ==
                    false
                  ) {
                    unelgeePath1 =
                      this.userData.school.ref.path +
                      "/unelgee2-" +
                      this.userData.school.currentYear +
                      "-scores" +
                      "/" +
                      student.STUDENT_GROUP_ID +
                      "-" +
                      this.currentSelectedLesson.courseInfo.COURSE_ID;
                    unelgeePath2 = unelgeePath1 + "/scores";
                  }
                  scoreUnelgee["lessonGroupId"] = student.lessonGroup.id;
                  scoreUnelgee["lessonGroupIndex"] =
                    student.lessonGroup.groupIndex;
                  scoreUnelgee["lessonGroupRef"] = student.lessonGroup.ref;
                }
                scoreUnelgee["lessonGroup"] = null;
                scoreUnelgee["schoolId"] = this.userData.school.id;
                scoreUnelgee["schoolName"] = this.userData.school.name;
                scoreUnelgee["createdByRef"] = this.userData.ref;
                scoreUnelgee["createdByName"] = this.userData["DISPLAY_NAME"]
                  ? this.userData["DISPLAY_NAME"]
                  : null;
                scoreUnelgee["lesson"] = null;
                scoreUnelgee["lessonId"] = this.currentSelectedLesson.id;
                scoreUnelgee["lessonRef"] = this.currentSelectedLesson.ref;
                scoreUnelgee["courseInfo"] =
                  this.currentSelectedLesson.courseInfo;
                scoreUnelgee["semester"] = 2;
                scoreUnelgee["year1"] = 1;
                scoreUnelgee["year"] = this.userData.school.currentYear;

                scoreUnelgee["createdAt"] = new Date();
                scoreUnelgee["updatedAt"] = new Date();

                // scoreUnelgee["score"] = scoreUnelgee["score"]
                // scoreUnelgee["score"] = scoreUnelgee["score"]
                // scoreUnelgee["description"] = scoreUnelgee["unelgee2"]["description"]

                scoreUnelgee["unelgee2"] = null;

                if (unelgeePath2.score) console.log(unelgeePath2);
                if (scoreUnelgee.scoreFinal) console.log(scoreUnelgee);
                if (student.unelgee1Scores)
                  scoreUnelgee["unelgee1Scores"] = student.unelgee1Scores;
                if (student.averageScore) {
                  scoreUnelgee["averageScore"] = student.averageScore;
                }
                if (student.highestScore) {
                  scoreUnelgee["highestScore"] = student.highestScore;
                  scoreUnelgee["highestScoreExam"] = student.highestScoreExam;
                }
                if (student.latestScore) {
                  scoreUnelgee["latestScore"] = student.latestScore;
                  scoreUnelgee["latestScoreExam"] = student.latestScoreExam;
                }
                if (student.scoreBasedOnState) {
                  scoreUnelgee["scoreBasedOnState"] = student.scoreBasedOnState;
                }
                if (student.scoreBasedOnExam) {
                  scoreUnelgee["scoreBasedOnExam"] = student.scoreBasedOnExam;
                }

                scoreUnelgee["gradedBy"] = this.userData;
                scoreUnelgee["sentToManager"] = true;

                // var unelgeeStudentPath = student.ref.path+"/"+"student-courses-"+this.userData.school.currentYear
                // + "/"+this.currentSelectedLesson.courseInfo.COURSE_ID
                // +"/student-unelgee1-"+this.userData.school.currentYear
                if (
                  student.changedScore ||
                  (student.scoreState != true && student.sentToManager != true)
                ) {
                  if (
                    scoreUnelgee.score != null &&
                    scoreUnelgee.score != undefined &&
                    typeof student.score == "number"
                  ) {
                    scoreUnelgee["sentToManager"] = true;
                    scoreUnelgee["sentToManagerAt"] = new Date();
                    if (
                      student.modifiedScore &&
                      student.scoreState != null &&
                      student.scoreState != -1
                    ) {
                      batch.update(
                        fb.db
                          .collection(unelgeePath2)
                          .doc(
                            this.currentSelectedLesson.esisLessonType
                              .esisLessonTypeId > 1
                              ? student.id + "-" + student.lessonGroup.id
                              : student.id
                          ),
                        {
                          approved: fb.firestore.FieldValue.delete(),
                          approvedAt: fb.firestore.FieldValue.delete(),
                          approvedByRef: fb.firestore.FieldValue.delete(),
                        }
                      );
                    }
                    // fb.db
                    //   .collection(unelgeePath2)
                    //   .doc(student.id)
                    //   .get()
                    //   .then((doc) => {
                    //     if (doc.exists && doc.data().score != scoreUnelgee.score) {
                    //       console.log(doc.data().score, scoreUnelgee.score);
                    //       doc.ref.update({
                    //         approved: fb.firestore.FieldValue.delete(),
                    //         approvedAt: fb.firestore.FieldValue.delete(),
                    //         approvedByRef: fb.firestore.FieldValue.delete(),
                    //       });
                    //     }
                    //   });

                    numberOfScoredStudents++;
                    // var docName1=this.currentSelectedExam.id+"-"+student.id
                    // if(this.currentSelectedLesson.isGroupBased){
                    //   docName1 = docName1 + "-"+student.lessonGroup.id
                    // }

                    batch.set(
                      fb.db
                        .collection(unelgeePath2)
                        .doc(
                          this.currentSelectedLesson.esisLessonType
                            .esisLessonTypeId > 1
                            ? student.id + "-" + student.lessonGroup.id
                            : student.id
                        ),
                      scoreUnelgee,
                      { merge: true }
                    );
                    // batch.set(fb.db.collection(unelgeeStudentPath).doc(docName2), scoreUnelgee,{merge:true})
                  }
                }
                if (scoreUnelgee.approved == true) {
                  scoreAverage = scoreAverage + scoreUnelgee.score;
                  approvedNumber++;
                  worked = true;
                } else if (scoreUnelgee.approved == false) {
                  rejectedNumber++;
                  worked = true;
                } else if (scoreUnelgee.sentToManager == true) {
                  inProgressNumber++;
                  worked = true;
                } else if (
                  scoreUnelgee.sentToManager != true &&
                  scoreUnelgee.score != null &&
                  scoreUnelgee.score != undefined &&
                  typeof scoreUnelgee.score == "number"
                ) {
                  worked = true;
                  sentNumber++;
                }
              }
            }

            var tmp = {};
            tmp["year1UnelgeeScores"] = {
              approvedNumber: 0,
              rejectedNumber: 0,
              inProgressNumber: 0,
              sentNumber: 0,
            };

            tmp["year1UnelgeeScores-" + this.userData.id] = {
              approvedNumber: approvedNumber,
              rejectedNumber: rejectedNumber,
              inProgressNumber: inProgressNumber,
              sentNumber: sentNumber,
              scoreAverage: (scoreAverage / approvedNumber).toFixed(2),
            };
            tmp["sentToManager"] = worked;
            this.currentSelectedLesson.byTeachers.forEach((teacher) => {
              if (
                this.currentSelectedLesson[
                  "year1UnelgeeScores-" + teacher.teacherId
                ]
              ) {
                if (teacher.teacherId == this.userData.id) {
                  tmp["year1UnelgeeScores"].approvedNumber =
                    tmp["year1UnelgeeScores"].approvedNumber +
                    tmp["year1UnelgeeScores-" + teacher.teacherId]
                      .approvedNumber;
                  tmp["year1UnelgeeScores"].rejectedNumber =
                    tmp["year1UnelgeeScores"].rejectedNumber +
                    tmp["year1UnelgeeScores-" + teacher.teacherId]
                      .rejectedNumber;
                  tmp["year1UnelgeeScores"].inProgressNumber =
                    tmp["year1UnelgeeScores"].inProgressNumber +
                    tmp["year1UnelgeeScores-" + teacher.teacherId]
                      .inProgressNumber;
                  tmp["year1UnelgeeScores"].sentNumber =
                    tmp["year1UnelgeeScores"].sentNumber +
                    tmp["year1UnelgeeScores-" + teacher.teacherId].sentNumber;
                } else {
                  tmp["year1UnelgeeScores"].approvedNumber =
                    tmp["year1UnelgeeScores"].approvedNumber +
                    this.currentSelectedLesson[
                      "year1UnelgeeScores-" + teacher.teacherId
                    ].approvedNumber;
                  tmp["year1UnelgeeScores"].rejectedNumber =
                    tmp["year1UnelgeeScores"].rejectedNumber +
                    this.currentSelectedLesson[
                      "year1UnelgeeScores-" + teacher.teacherId
                    ].rejectedNumber;
                  tmp["year1UnelgeeScores"].inProgressNumber =
                    tmp["year1UnelgeeScores"].inProgressNumber +
                    this.currentSelectedLesson[
                      "year1UnelgeeScores-" + teacher.teacherId
                    ].inProgressNumber;
                  tmp["year1UnelgeeScores"].sentNumber =
                    tmp["year1UnelgeeScores"].sentNumber +
                    this.currentSelectedLesson[
                      "year1UnelgeeScores-" + teacher.teacherId
                    ].sentNumber;
                }
              }
            });
            console.log(tmp);
            //currentSelectedLesson
            //24115 end ammjittai bolvol line 213 g oorchilvol yamar ve?
            batch.set(this.currentSelectedLesson.ref, tmp, { merge: true });
            batch.set(
              fb.db.doc(unelgeePath1),
              {
                numberOfScoredStudents: numberOfScoredStudents,
                numberOfStudents: this.students.length,
                calculationModel: this.simpleCalculationModel,
                createdAt: new Date(),
              },
              { merge: true }
            );
            batch.commit().then(() => {
              console.log(tmp);
              var lesson = this.lessons.find(
                (lesson) => lesson.id == this.currentSelectedLesson.id
              );
              lesson["year1UnelgeeScores-" + this.userData.id] =
                tmp["year1UnelgeeScores-" + this.userData.id];
              this.$forceUpdate();
              this.savingUnelgee = false;
              for (var student of this.students) {
                student.score = null;
                student.scoreFinal = null;
              }
              this._closeUnelgeeDialog();

              // this.getLessonScoreAnalytics(this.currentSelectedLesson);
            });

            console.log(numberOfScoredStudents);
            console.log(
              this.currentSelectedLesson.courseInfo.COURSE_ID,
              this.currentSelectedLesson.courseInfo
            );
          }
        });
      } else {
        this.$swal({
          title: "Бүх сурагч дээр дүн орсон тохиолдолд илгээх боломжтой!",
          text: "Бүх сурагчийн дүнгийг оруулсаны дараа дахин оролдоно уу.",
          type: "warning",
          showCancelButton: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          confirmButtonText: "Ок",
          reverseButtons: true,
        });
      }
    },
    _print2(item) {
      console.log(item.ref.path);
    },
    _removeFromLesson(lesson) {
      this.$swal({
        title: "Та энэ xичээлийг заадаггүй юу?",
        text: "Xэрэв та энэ xичээлийг заадаггүй тань дээр үүссэн, эсвэл зааxаа болисон бол 'ТИЙМ' гэснээр энэ xичээл таниас xасагдана!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм, би заадаггүй",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          lesson.ref.update({
            teacherRefs: fb.firestore.FieldValue.arrayRemove(this.userData.ref),
          });
        }
      });
    },
    _print(lesson) {
      console.log(lesson.ref.path, lesson);
      console.log(
        this.userData.school.ref.path +
          "/courses-" +
          this.userData.school.currentYear +
          "/" +
          lesson.courseInfo.COURSE_ID
      );
    },
    _getLessonClassGroupsName(lesson) {
      var str = null;
      if (!lesson.isCollected) {
        for (const gg of lesson.classGroups) {
          str = str ? str + ", " + gg.classGroupName : gg.classGroupName;
        }
      } else if (lesson.subLessons) {
        // console.log(lesson.isCollected, lesson.name2, lesson);
        for (const xx of lesson.subLessons) {
          if (xx.classGroups && xx.classGroups[0]) {
            str = str
              ? str + ", " + xx.classGroups[0].classGroupFullName
              : xx.classGroups[0].classGroupFullName;
          }
        }
      }
      return str;
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },

    // isAllowedLesson(lesson) {
    //   if (lesson && lesson.classGroupRefs) {
    //     for (var ll of lesson.classGroupRefs) {
    //       if (ll.path.includes("/departments/")) {
    //         lesson.isNotAllowed = true;
    //         break;
    //       }
    //     }
    //   }
    // },
    calculateSumOfLevels(lesson) {
      var sum = 0;
      if (lesson["year1UnelgeeScores-" + this.userData.id]) {
        if (lesson["year1UnelgeeScores-" + this.userData.id]["_VIII"])
          sum = sum + lesson["year1UnelgeeScores-" + this.userData.id]["_VIII"];
        if (lesson["year1UnelgeeScores-" + this.userData.id]["_VII"])
          sum = sum + lesson["year1UnelgeeScores-" + this.userData.id]["_VII"];

        if (lesson["year1UnelgeeScores-" + this.userData.id]["_VI"])
          sum = sum + lesson["year1UnelgeeScores-" + this.userData.id]["_VI"];

        if (lesson["year1UnelgeeScores-" + this.userData.id]["_V"])
          sum = sum + lesson["year1UnelgeeScores-" + this.userData.id]["_V"];

        if (lesson["year1UnelgeeScores-" + this.userData.id]["_IV"])
          sum = sum + lesson["year1UnelgeeScores-" + this.userData.id]["_IV"];

        if (lesson["year1UnelgeeScores-" + this.userData.id]["_III"])
          sum = sum + lesson["year1UnelgeeScores-" + this.userData.id]["_III"];

        if (lesson["year1UnelgeeScores-" + this.userData.id]["_II"])
          sum = sum + lesson["year1UnelgeeScores-" + this.userData.id]["_II"];

        if (lesson["year1UnelgeeScores-" + this.userData.id]["_I"])
          sum = sum + lesson["year1UnelgeeScores-" + this.userData.id]["_I"];
      }
      return sum;
    },

    async _setupp() {
      if (this.userData.school) {
        var query = this.userData.school.ref
          .collection("lessons-" + this.userData.school.currentYear)
          .where("deleted", "==", false);

        if (this.userData.role == "teacher") {
          query = query.where(
            "teacherRefs",
            "array-contains",
            this.userData.ref
          );
        }
        query = query.orderBy("courseInfo.COURSE_NAME", "asc");
        this.loading = true;
        query.onSnapshot((docs) => {
          this.lessons = [];
          docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;
            if (!lesson.deleted) {
              if (
                lesson.classGroups &&
                lesson.classGroups.length &&
                lesson.classGroups[0] &&
                lesson.classGroups[0].ACADEMIC_LEVEL == "3" &&
                lesson.courseInfo &&
                lesson.courseInfo.SUBJECT_AREA_ID == 245 &&
                lesson.courseInfo.COURSE_NAME.includes("2")
              ) {
                var ll = await lesson.courseInfo.ref.get()
                var llData = ll.data()
                llData.id = ll.id
                llData.ref = ll.ref
                await lesson.ref.update({courseInfo: llData}).then(()=>{
                  lesson.courseInfo = llData
                  this.$forceUpdate()
                })
              }
              this.lessons.push(lesson);
            }

            // if (
            //   !lesson.deleted &&
            //   this._isPrimaryClass(lesson) &&
            //   this.userData.school &&
            //   this.userData.school.id != "bIZDwo6lAhHPQLBfdfrX"
            // )
            //   this.lessons.push(lesson);
            // else if (!lesson.deleted) this.lessons.push(lesson);
            // var byTeachers = [];
            // lesson.byTeachers.forEach((teacher) => {
            //   if (
            //     teacher &&
            //     teacher.teacherId &&
            //     byTeachers.find((tt) => tt.teacherId == teacher.teacherId) ==
            //       undefined
            //   ) {
            //     byTeachers.push(teacher);
            //   }
            // });
            // lesson.byTeachers = byTeachers;
            // lesson.topics = null;
            // lesson.name2 = lesson.courseInfo.COURSE_NAME;
            // lesson.name3 = counter + ". " + lesson.courseInfo.COURSE_NAME;
            // // this.isAllowedLesson(lesson);
            // // console.log("lesson",lesson)
            // lesson.classGroups
            //   ? lesson.classGroups.forEach((cg) => {
            //       lesson.name3 =
            //         lesson.name3 +
            //         " - " +
            //         (cg.STUDENT_GROUP_NAME
            //           ? cg.STUDENT_GROUP_NAME
            //           : cg.classGroupFullName
            //         ).toUpperCase();
            //     })
            //   : "";
            // if (lesson.byTeachers.length == 1) {
            //   if (
            //     lesson["year1UnelgeeScores"] &&
            //     (lesson["year1UnelgeeScores-" + this.userData.id] ==
            //       undefined ||
            //       lesson["year1UnelgeeScores"].approvedNumber !=
            //         lesson["year1UnelgeeScores-" + this.userData.id]
            //           .approvedNumber ||
            //       lesson["year1UnelgeeScores"].rejectedNumber !=
            //         lesson["year1UnelgeeScores-" + this.userData.id]
            //           .rejectedNumber ||
            //       lesson["year1UnelgeeScores"].inProgressNumber !=
            //         lesson["year1UnelgeeScores-" + this.userData.id]
            //           .inProgressNumber ||
            //       lesson["year1UnelgeeScores"].sentNumber !=
            //         lesson["year1UnelgeeScores-" + this.userData.id].sentNumber)
            //   ) {
            //     var tmp = {};
            //     tmp["year1UnelgeeScores-" + this.userData.id] =
            //       lesson["year1UnelgeeScores"];
            //     lesson.ref.update(tmp);
            //   }
            // }

            // if (
            //   (!lesson["year1UnelgeeScores-" + this.userData.id] ||
            //     this.calculateSumOfLevels(lesson) !=
            //       lesson["numberOfStudents-" + this.userData.id] ||
            //     !lesson["year1UnelgeeScores-" + this.userData.id]
            //       .sumOfFinalScores) &&
            //   lesson["year1UnelgeeScores"] &&
            //   lesson["year1UnelgeeScores"].approvedNumber > 0
            // ) {
            //   this._calculateTeacherExecution(lesson);
            // }

            // if (lesson.byTeachers.length > 0 && !lesson.isNotAllowed) {
            //   // setTimeout(() => {
            //   //   // this.getClassStudentNumber(lesson);
            //   //   // if (checkAgain)
            //   //   // this.getLessonScoreAnalytics(lesson, checkAgain);
            //   // }, 2000);

            //   //this._correctize(counter, lesson);
            //   this.loading = false;
            //   this.lessons.push(lesson);
            // }
          });
          this.loading = false;
        });
      }
    },
    // _correctize(counter, lesson) {
    //   if (
    //     lesson["year1UnelgeeScores-" + this.userData.id] &&
    //     lesson["year1UnelgeeScores-" + this.userData.id].inProgressNumber == 0
    //   ) {
    //     // console.log(
    //     //   counter,
    //     //   lesson.courseInfo.COURSE_NAME,
    //     //   lesson.esisLessonType.esisLessonTypeId
    //     // );
    //   } else {
    //     console.log(
    //       counter,
    //       lesson.courseInfo.COURSE_NAME,
    //       lesson.esisLessonType.esisLessonTypeId
    //     );
    //   }
    // },
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    getYoutubeIframe(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      ) {
        var id = this.getYoutubeId(url);
        return "https://www.youtube.com/embed/" + id;
      } else {
        return "https://www.loom.com/embed/459052232f0c4df69be88d71515571d4";
      }
    },
    closeVideo() {
      this.$refs.plyr.player.pause();
      //this.$refs.plyr.player.restart();
      this.showVideoDialog = !this.showVideoDialog;
    },
  },
  created() {
    var colRef = this.userData.ref
      .collection("unelgee2")
      .doc(this.userData.school.currentYear + "-1");
    console.log(colRef.path, "unelge2ref");
    colRef.onSnapshot((doc) => {
      if (doc.exists) {
        this.unelgee2StatisticsOfTeacher = doc.data();
        this.unelgee2StatisticsOfTeacher.id = doc.id;
        this.unelgee2StatisticsOfTeacher.ref = doc.ref;
      } else {
        this.unelgee2StatisticsOfTeacher = { completed: false };
      }
    });

    this._setupp();
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}

.unelgee2table td {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding: 0 !important;
}

.unelgee2table th {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding: 0 !important;
}
.scoreColumn {
  background-color: #caefff;
}
.active-tab {
  background-color: #ffc107;
  color: #ffffff;
}
</style>

<template>
  <v-card class="px-0 mt-0" height="450">
    <template>
      <div class="px-10 pt-4 d-flex flex-row ma-0">
        <span>
          <span class="blue--text" v-if="$attrs.lesson.students"
            >Нийт сурагчийн тоо: </span
          >{{ $attrs.lesson.students.length }} - {{ $attrs.externExamsExists2 }}
          <!-- <span class="blue--text">Бүх Дундаж:</span>
          {{ lessonAverageScore }} -->
          <!-- <span class="blue--text"> Батлагдсан дундаж:</span>
          {{
            $attrs.lesson[
              "year" + $attrs.selectedHalfYear.yearId + "UnelgeeScores"
            ] &&
            $attrs.lesson[
              "year" + $attrs.selectedHalfYear.yearId + "UnelgeeScores"
            ].scoreAverage
              ? $attrs.lesson[
                  "year" + $attrs.selectedHalfYear.yearId + "UnelgeeScores"
                ].scoreAverage
              : "-"
          }} -->
        </span>
        <v-spacer></v-spacer>
        <!-- <v-col cols="3" class="py-0 mr-4">
          <v-select
            dense
            v-model="selectedGradingMethod"
            label="Бүлэг сэдвийн үнэлгээ (Үнэлгээ-I)"
            item-text="name"
            item-value="value"
            :items="gradingMethods"
          >
          </v-select>
        </v-col> -->
        <template v-if="checkAllGradesDone(true) && $attrs.externExamsExists2">
          <v-btn
            class="red white--text mr-4"
            small
            @click="_retrieveExternExamScore()"
            v-text="'Гадны шалгалтын дүн аваx?'"
          >
          </v-btn>
        </template>
        <v-btn
          v-if="unelgee1Retrieved && unelgee1RetrievedLength == 1"
          class="amber mr-4"
          small
          @click="_unelgee1Copy(null, null)"
          v-text="'Үнэлгээ1 дүнг аваx?'"
        >
        </v-btn>
        <template
          v-else-if="
            unelgee1Retrieved &&
            unelgee1RetrievedLength > 0 &&
            checkAllGradesDone(false)
          "
        >
          <span
            :class="selectedUnelgee1Mode == 'latest' ? 'amber' : ''"
            class="ml-2 mr-4"
            small
            @click="_unelgee1Copy(null, 'latest')"
            style="cursor: pointer"
            >Сүүлийн дүнгээр аваx?</span
          >

          <span
            :class="selectedUnelgee1Mode == 'average' ? 'amber' : ''"
            class="mr-4"
            small
            @click="_unelgee1Copy(null, 'average')"
            style="cursor: pointer"
            >Дундаж дүнгээр аваx?</span
          >
          <span
            class="ml-2 mr-4"
            :class="selectedUnelgee1Mode == 'highest' ? 'amber' : 'red--text'"
            small
            @click="_unelgee1Copy(null, 'highest')"
            style="cursor: pointer"
            >ИX дүнгээр аваx?</span
          >
        </template>

        <v-btn
          v-else-if="checkAllGradesDone(true)"
          class="mr-4"
          elevation="0"
          style="background-color: red; color: white"
          small
          @click="useGradingMethodAll()"
          v-text="'Үнэлгээ 1 дүн татаx?'"
        ></v-btn>

        <v-btn
          v-if="
            checkAllGradesDone(false) &&
            ($attrs.lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
              $attrs.lesson.courseInfo.GRADING_SCHEME_ID == 14)
          "
          class="green white--text mr-4"
          small
          @click="setAllToozov"
          >Тооцов болгоx</v-btn
        >
        <v-btn
          elevation="0"
          small
          class="mr-4"
          style="background-color: #ffebee; color: red"
          v-if="isAllCancelAble"
          @click="doAllCancel"
          >БҮX дүнг устгаx уу?</v-btn
        >
      </div>
      <v-simple-table
        v-if="$attrs.lesson.unelgee2Scores"
        class="px-10 pt-2 pb-2 scoreTables"
        style="height: 100%; overflow-y: auto"
      >
        <tbody>
          <tr style="background-color: #ececec" class="mt-2 pt-2">
            <th class="font-weight-bold black--text text-center">No.</th>
            <th class="font-weight-bold black--text pl-2">Нэр</th>
            <th class="font-weight-bold black--text pl-2">Бүлэг</th>
            <!-- <th
              class="font-weight-bold black--text text-center"
              v-if="!$attrs.simpleCalculationModel"
            >
              Ирц (10%)
              <v-btn
                dark
                x-small
                color="red"
                elevation="0"
                @click="_readAttendances"
                >Ирц татах</v-btn
              >
            </th> -->
            <th class="font-weight-bold black--text text-center">
              <div class="d-flex flex-row ma-0">
                <!-- <v-col
                  cols="4"
                  style="
                    border-right: 2px solid #dddddd;
                    background-color: #c9daf8 !important;
                  "
                  class="pa-0"
                  >Дундаж</v-col
                > -->
                <v-col cols="8" class="pa-0">Үнэлгээ - I</v-col>
              </div>
            </th>
            <th
              class="font-weight-bold black--text text-center"
              v-if="$attrs.externExamsExists2"
            >
              ТТШ
            </th>
            <th class="font-weight-bold black--text text-center">
              <span v-if="$attrs.externExamsExists2">Багшийн дүн (%) </span>
              <span v-else> Эцсийн дүн (%) </span>
            </th>
            <th
              class="font-weight-bold black--text text-center px-2"
              v-if="$attrs.externExamsExists2"
            >
              Үнэлгээ 2
            </th>

            <th class="font-weight-bold black--text text-center px-2">
              Түвшин
            </th>
            <!-- <th class="font-weight-bold black--text text-center">Тайлбар</th> -->
            <th class="font-weight-bold black--text text-center">Төлөв</th>
            <th class="font-weight-bold black--text text-center px-2">
              Үйлдэл
            </th>
          </tr>
          <tr
            v-for="(item, ii) in filteredStudents"
            :key="ii + '-' + item.index"
          >
            <template v-if="item.separator">
              <td
                colspan="8"
                class="pl-2 blue--text"
                style="background-color: #ececec; height: 20px !important"
              >
                <!-- {{ item.teacherDisplayName }}   -->
                {{ item.name }} ({{ item.numberOfStudents }})
              </td>
            </template>

            <template style="width: 100%" v-else>
              <td class="text-center" @click="_print(item)" style="width: 1%">
                {{ item.index }}
              </td>
              <td class="px-2" style="max-width: 40%; cursor: pointer">
                <!-- @click="_deleteItem(item)" 2511-->
                {{ item["FIRST_NAME"]
                }}<span class="grey--text"
                  >, {{ item["LAST_NAME"] }} {{ _getBD(item) }}
                  <!-- {{ item.register_no }} -->
                </span>
                <span class="red--text" v-if="isNoExternalScore(item)"
                  >Шалгалт дүнгүй! {{ externalScoresPulled }}</span
                >
              </td>

              <td class="text-center">
                <span class="blue--text">
                  {{ item["STUDENT_GROUP_NAME"] }}</span
                >
              </td>

              <!-- <td class="text-center" v-if="!$attrs.simpleCalculationModel">
                {{ getStudentAttendances(item) }}
              </td> -->
              <td class="text-center">
                <span v-if="item.unelgee1">{{
                  item.unelgee1.map((s) => s.score).join(", ")
                }}</span>
                <v-progress-linear
                  v-else-if="scoreLoading"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
                <!-- Үнэлгээ1 ирнэ -->
                <!-- <div class="ma-0 d-flex flex-row" style="height: 100%">
                  <v-col
                    cols="4"
                    @click="
                      useGradingMethod({ score: item.averageScore }, item)
                    "
                    class="pa-0 pt-1"
                    title="Дүн ашиглах"
                    style="border-right: 2px solid #dddddd"
                    :style="
                      (item.scoreState != true && item.sentToManager != true
                        ? 'cursor: pointer;'
                        : '') +
                      (selectedGradingMethod == 0
                        ? 'background-color: #fdd270;'
                        : 'background-color: #c9daf8;')
                    "
                  >
                    {{ getUnelgee1Scores(item) }}
                 
                  </v-col>
                  <v-col
                    class="pa-0 pt-1"
                    @click="useGradingMethod(score, item)"
                    v-for="(score, sindex) in item.unelgee1Scores"
                    :key="'score' + sindex + score.score"
                    :style="
                      (item.scoreState != true && item.sentToManager != true
                        ? 'cursor: pointer;'
                        : '') +
                      ((selectedGradingMethod == 1 &&
                        score.score == item.highestScore) ||
                      (selectedGradingMethod == 2 &&
                        score.score == item.latestScore)
                        ? 'background-color: #fdd270;'
                        : '' +
                          (sindex + 1 == item.unelgee1Scores.length
                            ? ''
                            : 'border-right: 2px solid #dddddd'))
                    "
                    :class="
                      sindex + 1 == item.unelgee1Scores.length ? '' : 'px-1'
                    "
                    >{{ score.score }}</v-col
                  >
                </div> -->
              </td>

              <template v-if="$attrs.externExamsExists2">
                <td
                  class="text-center"
                  :style="
                    !(
                      item.eScore &&
                      item.eScore.eScore != undefined &&
                      item.eScore.eScore != null
                    )
                      ? 'background-color:red'
                      : 'background-color: #bbdefb'
                  "
                >
                  <span
                    v-if="
                      item.eScore &&
                      item.eScore.eScore != undefined &&
                      item.eScore.eScore != null
                    "
                    >{{ item.eScore.eScore }}</span
                  >
                </td>
                <td style="max-width: 15%; background: #ffebee">
                  <div
                    class="text-center"
                    v-if="item.scoreState == true || item.sentToManager == true"
                  >
                    {{ item.score1 }}
                  </div>
                  <input
                    v-else
                    @keyup.enter="focusNextField"
                    outlined
                    class="text-center pl-3 score-bordered-input"
                    :id="ii"
                    type="number"
                    v-model.number="item.score1"
                    style="width: 100%"
                    :max="100"
                    :min="0"
                    @click="
                      item.score1 != null &&
                      item.score1 != undefined &&
                      typeof item.score1 == 'number'
                        ? (previousValue = item.score1)
                        : (previousValue = null)
                    "
                    @keyup="checkValue(item)"
                    @change="
                      _getFinalUnelgee2(item);
                      calScoreLevel(item);
                      item.modifiedScore = true;
                    "
                  />
                </td>
                <!-- final unelgee2 -->
                <td class="text-center">{{ item.score }}</td>
              </template>
              <template v-else>
                <td style="max-width: 15%; background: #ffebee">
                  <div
                    class="text-center"
                    v-if="item.scoreState == true || item.sentToManager == true"
                  >
                    {{ item.score }}
                  </div>
                  <input
                    v-else
                    @keyup.enter="focusNextField"
                    outlined
                    class="text-center pl-3 score-bordered-input"
                    :id="ii"
                    type="number"
                    v-model.number="item.score"
                    style="width: 100%"
                    :max="100"
                    :min="0"
                    @click="
                      item.score != null &&
                      item.score != undefined &&
                      typeof item.score == 'number'
                        ? (previousValue = item.score)
                        : (previousValue = null)
                    "
                    @keyup="checkValue(item)"
                    @change="
                      calScoreLevel(item);
                      item.modifiedScore = true;
                    "
                  />
                </td>
              </template>
              <!-- level string -->
              <td class="text-center">
                <span v-if="item.scoreFinal == 0" class="red--text">{{
                  _getCorrectScoreString(item)
                }}</span>
                <span v-else class="green--text">{{
                  _getCorrectScoreString(item)
                }}</span>
              </td>
              <!-- <td style="width: 25%">
              <input
                class="text-center"
                :id="item.id + 'description'"
                v-model.trim="item.description"
                style="width: 100%"
              />
            </td> -->
              <td class="text-center">
                <span
                  text
                  v-if="item.scoreState != -1"
                  x-small
                  :class="
                    item.scoreState == true
                      ? 'green--text'
                      : item.scoreState == false
                      ? 'red--text'
                      : item.sentToManager == true
                      ? 'orange--text'
                      : 'blue--text'
                  "
                  class="ml-2 mr-3"
                  style="text-transform: none"
                  v-text="
                    item.scoreState == true
                      ? 'Баталгаажсан'
                      : item.scoreState == false
                      ? 'Цуцлагдсан'
                      : item.sentToManager == true
                      ? 'Хянагдаж байгаа'
                      : 'Бүртгэсэн'
                  "
                >
                </span>
                <span
                  class="px-2 red--text text-center"
                  v-else-if="
                    (item.score == null || item.score == undefined) &&
                    typeof item.score != 'number'
                  "
                  >Дүнгүй</span
                >
              </td>
              <td class="text-center">
                <v-btn
                  @click="_clearScore(item)"
                  text
                  v-if="
                    item.scoreState != -1 &&
                    item.scoreState != true &&
                    item.sentToManager != true
                  "
                  x-small
                  class="white--text ml-2 mr-3 bg-gradient-danger"
                  style="text-transform: none"
                >
                  Устгах
                </v-btn>
              </td>
              <!-- <td>{{item.deleted}} - {{item.sentToManager}}</td>   -->
            </template>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-else class="mt-16 pt-16">
        <v-progress-linear
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
      </div>
      <v-dialog v-model="dialogDelete" max-width="600px" v-if="editedItem">
        <v-card class="card-shadow card-padding border-radius-xl py-10">
          <v-card-title
            class="pt-0 text-h4 text-typo justify-censentToManagerter"
            >Группт байдаггүй сурагч уу?</v-card-title
          >
          <v-card-text class="text-h5 mt-6">
            <span class="font-weight-bold text-uppercase"
              >{{ editedItem["FIRST_NAME"] }},</span
            >
            {{ editedItem["LAST_NAME"] }}
          </v-card-text>
          <v-card-text>
            <span style="color: red">
              Энэxүү үйлдлийг xийсэнээр таны ангийн сурагч группээс
              хасагдана.</span
            >
          </v-card-text>
          <!-- <v-card-text>
            Та энэ сурагчийг
            <span class="font-weight-bold" style="color: red"
              >аль ангид сурдагийг мэдэx бол</span
            >
            сонгоно уу. Мэдэxгүй бол сонгоxгүй байж болно.
          </v-card-text> -->
          <v-card-actions class="pb-0 mt-4">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDelete"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
              >Цуцлаx</v-btn
            >
            <v-btn
              @click="_moveStudent(editedItem)"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
              >Манай группын сурагч биш</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-card>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
export default {
  components: {},
  data: () => ({
    externExamsExists: false, // for school 1 special, if exits extern exam scores exist
    externExamsExists2: false,
    selectedUnelgee1Mode: null,
    unelgee1Retrieved: false,
    unelgee1RetrievedLength: 0,
    scoreLoading: false,
    studentsStatuses: null,
    alertText: null,
    showAlert: false,
    selectedEeljTsag: null,
    selectedLessonDayReport: null,
    attends: null,
    selectedStudent: null,
    selectedXXDay: null,
    selectedReasons: null,
    forParents: false,
    forStudent: false,
    forClassTeacher: false,
    forManager: false,
    xlessonStudentNotes: null,
    noteAdded: null,
    dialogAddNote: false,

    query: null,
    bodyNames: null,
    endOfDay: 30,
    collectionName: "attendances",
    docName: "attendance",
    lessonAverageScore: 0,

    currentDays: null,
    teachedTopics: null,
    curriculumDialog: null,
    selectedSubtopic: null,
    // selectedHomeworks: null,
    newDaalgavarName: null,
    previousSelectedDaalgavar: null,
    headerNames: [
      {
        text: "No.",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },

      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },
    ],
    attendances: null,
    loading: false,
    previousValue: null,
    editedItem: null,
    dialogDelete: false,
    totalNumberOfStudents: null,
    alreadyCollectedGroup: null,
    selectedGradingMethod: 2,
    gradingMethods: [
      {
        name: "Дундаж дүнгээр",
        value: 0,
      },
      {
        name: "Хамгийн өндөр шалгалтын дүнгээр",
        value: 1,
      },
      {
        name: "Сүүлийн шалгалтын дүнгээр",
        value: 2,
      },
    ],
    externalScoresPulled: false,
  }),
  mixins: [myfunction],
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),

    filteredStudents() {
      var list = [];
      // var prevGroupIndex;
      if (this.$attrs.lesson.students != null) {
        for (var stud of this.$attrs.lesson.students) {
          this.getStudentScoreState(stud);
          list.push(stud);
        }
      }
      return list;
    },
  },
  created() {
    //checking extern exam scores exist
    // if(this.$attrs.lesson.es)
    // console.log(this.$attrs.lesson.ref.path);
    // this._isWithExternExam(this.$attrs.lesson);
    this.externExamsExists2 == this.$attrs.externExamsExists2;
    console.log(this.externExamsExists2);

    this.totalNumberOfStudents = 0;
    this.alreadyCollectedGroup = [];
    this.calculateAverageScore();
  },
  methods: {
    isAllCancelAble() {
      var canceli = false;
      for (const stud of this.filteredStudents) {
        var statee =
          stud.scoreState != -1 &&
          stud.scoreState != true &&
          stud.sentToManager != true;
        if (statee) canceli = true;
        else canceli = false;
      }
      return canceli;
    },
    doAllCancel() {
      this.$swal({
        title: "Бүx дүнг устгаx уу?",
        text: "Тийм гэж зөвшөөрөөд xадгалсаны дараа xүчинтэй. Xэрэв та ТИЙМ үйлдлийг xийгээд ГАРАXДАА цуцлаx гээд гарвал xэвээр үлдэнэ. ",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          for (const stud of this.filteredStudents) {
            var statee =
              stud.scoreState != -1 &&
              stud.scoreState != true &&
              stud.sentToManager != true;
            if (statee) this._clearScore(stud);
          }
        }
      });
    },
    // checkingIfExtScoreExists(item) {
    //   if (
    //     this.$attrs.externExamsExists2 &&
    //     (this.externalScoresPulled ||
    //       this.filteredStudents.find((score) => score.eScore)) &&
    //     !(
    //       item.eScore &&
    //       item.eScore.eScore != undefined &&
    //       item.eScore.eScore != null
    //     )
    //   ) {
    //     // console.log(item);
    //     if (!item.register && !item.register_no) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   } else {
    //     return false;
    //   }
    // },

    isNoExternalScore(item) {
     if (
        this.$attrs.externExamsExists2 &&
        (this.externalScoresPulled ||
          this.filteredStudents.find((score) => score.eScore)) &&
        !(
          item.eScore &&
          item.eScore.eScore != undefined &&
          item.eScore.eScore != null
        )
      ) {
        // console.log(item);
        if (!item.register && !item.register_no) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    checkAllGradesDone(mode) {
      if (mode) {
        if (
          (this.$attrs.lesson &&
            this.$attrs.lesson.unelgee2Scores &&
            this.$attrs.lesson.students &&
            this.$attrs.lesson.unelgee2Scores.find(
              (score) => score.approved == false || !score.sentToManager
            )) ||
          (this.$attrs.lesson.unelgee2Scores &&
            this.$attrs.lesson.students &&
            this.$attrs.lesson.unelgee2Scores.length !=
              this.$attrs.lesson.students.length)
        ) {
          return true;
        }
      }
      if (
        this.$attrs.lesson &&
        this.$attrs.lesson[
          "sentToAllManager-" + this.$attrs.selectedHalfYear.yearId
        ]
      ) {
        var tmp = true;
        this.$attrs.lesson.unelgee2Scores.forEach((score) => {
          if (score.approved == false) {
            //
          } else {
            tmp = false;
          }
        });
        return tmp;
      } else {
        return true;
      }
    },
    _getFinalUnelgee2(item) {
      var finalScore = 0;
      if (item.score1) {
        finalScore = item.score1 * 0.7;
      }
      if (item.eScore && item.eScore.eScore) {
        finalScore = finalScore + item.eScore.eScore * 0.3;
      }
      item.score = finalScore ? Number(finalScore.toFixed(0)) : 0;
    },
    _getShortNameCodeForExternExam(lesson) {
      //undesnii bichig 4
      //uran zohiol 3
      //niigem sudlal 195
      //design zz 233
      //1229
      var input = lesson.courseInfo.SUBJECT_AREA_ID;
      switch (input) {
        case 2:
          return "mx";
        case 3:
          return "mx";
        case 4:
          return "mx";

        case 5:
          return "mat";
        case 9:
          return "x"; //himi

        case 11:
          return "bio";

        case 12:
          return "gz";

        case 10:
          return "f"; //physics

        case 13:
          return "tuuh"; //tuuh, niigem

        case 18:
          return "tex"; //tuuh, niigem
        case 20:
          return "xj";
        case 24:
          return "ax";

        case 25:
          return "ox";

        case 194:
          return "tuuh"; // niigem
        case 195:
          return "tuuh"; // niigem

        case 196:
          return "mt";

        case 233:
          return "tex"; //ddzz

        default:
          return null;
      }
    },
    //25107
    // _isAllowedForExternExams(acdemicLevel, subjectAreaId) {
    //   if (acdemicLevel == "6" && subjectAreaId == 3) return false; //uran zohiol
    //   else if (acdemicLevel == "6" && subjectAreaId == 4)
    //     return false; //undesnii bichig
    //   else if (acdemicLevel == "8" && subjectAreaId == 3)
    //     return false; //uran zohiol
    //   else if (acdemicLevel == "8" && subjectAreaId == 4)
    //     return false; //undesnii bichig
    //   else if (acdemicLevel == "9" && subjectAreaId == 4)
    //     return false; //undesnii bichig
    //   else if (acdemicLevel == "10" && subjectAreaId == 4)
    //     return false; //undesnii bichig
    //   else if (acdemicLevel == "11" && subjectAreaId == 3)
    //     return false; //uran zohiol
    //   else if (acdemicLevel == "11" && subjectAreaId == 4)
    //     return false; //undesnii bichig
    //   else return true;
    // },
    // async _isWithExternExam(lesson) {
    //   //25106
    //   var classGroup = lesson.classGroups[0];
    //   var allowed = this._isAllowedForExternExams(
    //     classGroup.ACADEMIC_LEVEL,
    //     lesson.courseInfo.SUBJECT_AREA_ID
    //   );
    //   if (lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY" && allowed) {
    //     // console.log(this._getShortNameCodeForExternExam(lesson), lesson,"lll",classGroup);
    //     if (this.userData.school.id == "bIZDwo6lAhHPQLBfdfrX" && classGroup) {
    //       var qq = await this.userData.school.ref
    //         .collection("externexams")
    //         .doc(this.userData.school.currentYear.toString())
    //         .collection(
    //           this._getShortNameCodeForExternExam(lesson) +
    //             "-" +
    //             classGroup.ACADEMIC_LEVEL
    //         )
    //         .limit(1)
    //         .get();
    //       this.externExamsExists2 = !qq.empty;
    //     } else return false;
    //   } else return false;
    // },
    async _retrieveExternExamScore() {
      if (this.$attrs.lesson.students.find((score) => score.eScore)) {
        var result = await this.$swal({
          title: "Дүн татагдсан байна. Ахиад шинэчлэн татаx уу?",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        });
      }

      if (!result || result.isConfirmed) {
        var classs = this.$attrs.lesson.classGroups[0];
        if (classs) {
          console.log(this.$attrs.lesson.classGroups[0].classGroupFullName);
          var qq = this.userData.school.ref
            .collection("externexams")
            .doc("2024")
            .collection(
              this._getShortNameCodeForExternExam(this.$attrs.lesson) +
                "-" +
                classs.ACADEMIC_LEVEL
            )
            .where("eClass", "==", classs.classGroupFullName);
          console.log(qq);
          qq.get().then((docs) => {
            docs.forEach((doc) => {
              let eScore = doc.data();
              var found = this.filteredStudents.find(
                (ss) =>
                  (ss.register_no &&
                    ss.register_no.trim().toLowerCase() ==
                      eScore.eRegister.trim().toLowerCase()) ||
                  (ss.register &&
                    ss.register.trim().toLowerCase() ==
                      eScore.eRegister.trim().toLowerCase())
              );
              if (found) {
                found.eScore = eScore;
                found.modifiedScore = true;
                this._getFinalUnelgee2(found);
                this.calScoreLevel(found);
                // console.log(eScore);
                //1228
              }
            });
            this.externalScoresPulled = true;
            this.$forceUpdate();
          });
        }
      }
      // console.log(this.$attrs.lesson);
    },
    _unelgee1Copy(item, calType) {
      //1225
      this.selectedUnelgee1Mode = calType;
      if (item) {
        this.calScoreLevel(item);
        item.modifiedScore = true;
      } else {
        for (const stud of this.filteredStudents) {
          var scoring = null;
          console.log(stud.unelgee1);
          if (this.unelgee1RetrievedLength == 1 && calType == null) {
            scoring = stud.unelgee1[0].score;
          } else if (this.unelgee1RetrievedLength > 1 && calType == "average") {
            if (stud.unelgee1 && stud.unelgee1.length > 0) {
              var sum = 0;
              for (const uu of stud.unelgee1) {
                sum = sum + uu.score;
              }
              scoring = Number((sum / stud.unelgee1.length).toFixed(1));
            } else {
              scoring = null;
            }
          } else if (this.unelgee1RetrievedLength > 1 && calType == "highest") {
            if (stud.unelgee1 && stud.unelgee1.length > 0) {
              const highest = Math.max(...stud.unelgee1.map((x) => x.score));

              scoring = highest;
            } else {
              scoring = null;
            }
          } else if (this.unelgee1RetrievedLength > 1 && calType == "latest") {
            if (stud.unelgee1 && stud.unelgee1.length > 0) {
              console.log(stud.unelgee1);
              const latest = stud.unelgee1[stud.unelgee1.length - 1].score;

              scoring = latest;
            } else {
              scoring = null;
            }
          }

          stud.modifiedScore = true;
          if (this.$attrs.externExamsExists2) {
            stud.score1 = Number(scoring);
            this._getFinalUnelgee2(stud);
            this.calScoreLevel(stud);
          } else {
            stud.score = Number(scoring);
            this.calScoreLevel(stud);
          }
          // console.log(stud.score);
        }
        this.$forceUpdate();
      }
    },
    setAllToozov() {
      for (const stud of this.filteredStudents) {
        stud.score = 100;
        stud.scoreFinal = 100;
        stud.scoreLevelNumber = 8;
        stud.scoreLevelString = "Тооцов";
        stud.modifiedScore = true;
        stud.scoreState = -1;
      }
      this.$forceUpdate();
    },
    calScoreLevel(item) {
      // item.score = item.score?Math.round(item.score):item.score
      item.scoreFinal = null;
      item.scoreFinal =
        item.score != null &&
        item.score != undefined &&
        typeof item.score == "number"
          ? item.score
          : 0;
      // console.log(item.scoreFinal);
      if (item.scoreFinal > 100) {
        this.$swal.fire("100>>");
        return item.score;
      }
      let gradePoints;
      let gradeStr;
      let tmpScore = item.scoreFinal;
      if (tmpScore >= 90) {
        gradePoints = 8;
        gradeStr = "VIII";
      } else if (tmpScore >= 80) {
        gradePoints = 7;
        gradeStr = "VII";
      } else if (tmpScore >= 70) {
        gradePoints = 6;
        gradeStr = "VI";
      } else if (tmpScore >= 60) {
        gradePoints = 5;
        gradeStr = "V";
      } else if (tmpScore >= 50) {
        gradePoints = 4;
        gradeStr = "IV";
      } else if (tmpScore >= 40) {
        gradePoints = 3;
        gradeStr = "III";
      } else if (tmpScore >= 30) {
        gradePoints = 2;
        gradeStr = "II";
      } else if (tmpScore >= 0 && typeof tmpScore == "number") {
        gradePoints = 1;
        gradeStr = "I";
      } else if (
        tmpScore == null ||
        tmpScore == undefined ||
        typeof tmpScore != "number"
      ) {
        gradePoints = null;
        gradeStr = null;
      }
      item.changedScore = true;
      item.scoreLevelNumber = gradePoints;
      item.scoreLevelString = gradeStr;
      this.previousValue = null;
      if (
        item.score > 0 &&
        (this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 14)
      ) {
        item.scoreLevelString = "Тооцов";
        item.score = 100;
        item.scoreFinal = 100;
        item.scoreLevelNumber = 8;
      } else if (
        item.score == 0 &&
        (this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 14)
      ) {
        item.scoreLevelString = "Тооцоогүй";
        item.score = 0;
        item.scoreFinal = 0;
        item.scoreLevelNumber = 1;
      }
      this.$forceUpdate();
    },
    focusNextField(event) {
      if (document.getElementById(String(Number(event.srcElement.id) + 1)))
        document
          .getElementById(String(Number(event.srcElement.id) + 1))
          .focus();
    },
    _getCorrectScoreString(item) {
      if (
        this.$attrs.lesson &&
        this.$attrs.lesson.courseInfo &&
        (this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 14)
      ) {
        if (item.scoreFinal > 0) return "Тооцсон";
        //return this.$attrs.lesson.courseInfo.GRADING_SCHEME_NAME;
        else if (item.scoreFinal == 0) return "Тооцоогүй";
        else return null;
      } else return item.scoreLevelString;
    },
    _getBD(student) {
      if (student["DATE_OF_BIRTH"])
        return ", " + student["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "");
      else return "";
    },
    async useGradingMethodAll() {
      if (
        this.$attrs.lesson.students.find(
          (score) => score.unelgee1 && score.unelgee1.length > 0
        )
      ) {
        var result = await this.$swal({
          title: "Дүн татагдсан байна. Даxиад шинэчлэн татаx уу?",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        });
      }

      if (!result || result.isConfirmed) {
        this.scoreLoading = true;
        this.userData.school.ref
          .collection("unelgee1-" + this.userData.school.currentYear)
          .doc(String(this.$attrs.lesson.courseInfo.COURSE_ID))
          .collection(
            "unelgee1-" + this.userData.school.currentYear + "-scores"
          )
          // .where("lessonId","==", this.$attrs.lesson.id)
          .orderBy("createdAt", "asc")
          .get()
          .then((docs) => {
            var batch = fb.db.batch();
            for (const stud of this.$attrs.lesson.students) {
              stud.unelgee1 = [];
              docs.forEach(async (doc) => {
                let ss = doc.data();
                ss.id = doc.id;
                ss.ref = doc.ref;

                if (
                  stud.PERSON_ID == ss.PERSON_ID &&
                  ss.createdByRef.id == this.userData.id
                ) {
                  var found = stud.unelgee1.find(xx=>xx.lessonId==ss.lessonId)
                  if(!found) stud.unelgee1.push(ss);
                  if (stud.unelgee1.length > this.unelgee1RetrievedLength)
                    this.unelgee1RetrievedLength = stud.unelgee1.length;
                }
                if(stud.id=="90000002653554"){
                  console.log("stud", stud.unelgee1.map(xx=>xx.lessonId),90000002653554)
                }
              });

              if (stud.unelgee1 && stud.unelgee1.length > 0) {
                batch.set(
                  this.userData.school.ref
                    .collection(
                      "unelgee2-" + this.userData.school.currentYear + "-scores"
                    )
                    .doc(this.$attrs.lesson.id)
                    .collection("scores")
                    .doc(String(stud.PERSON_ID)),
                  {
                    unelgee1: stud.unelgee1.map((x) => {
                      return {
                        score: x.score,
                        examRef: x.examRef,
                        scoreLevelString: x.scoreLevelString,
                      };
                    }),
                  },
                  { merge: true }
                );
              }
            }
            if (this.unelgee1RetrievedLength > 0) {
              var tmp = {};
              tmp["unelgee1ScoresPulled-1"] = true;
              tmp["unelgee1ScoresPulledAt-1"] = new Date();
              batch.update(this.$attrs.lesson.ref, tmp);
            }

            //1230 ene commit comment logdson baisan!!!
            batch.commit().then(() => {
              console.log("done-saved!");
            });
            if (this.unelgee1RetrievedLength > 0) {
              this.$attrs.lesson["unelgee1ScoresPulled-1"] = true;
              this.unelgee1Retrieved = true;
            } else {
              this.$swal.fire("Үнэлгээ 1 дүн байхгүй байна!");
            }
            this.scoreLoading = false;
            this.$forceUpdate();
          });
      }
    },
    useGradingMethodAll2() {
      this.$swal({
        title: "Үнэлгээ1 -ийн дүнг Үнэлгээ 2-т хуулах уу?",
        text: "Та сонгосон Үнэлгээ 1-ийнхээ дүнг Үнэлгээ 2-ийн баганад бүх сурагч дээр хуулах боломжтой!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$attrs.lesson.students.forEach((student) => {
            if (this.selectedGradingMethod == 0) {
              if (student.averageScore)
                this.useGradingMethod({ score: student.averageScore }, student);
            } else if (this.selectedGradingMethod == 1) {
              if (student.highestScoreExam)
                this.useGradingMethod(student.highestScoreExam, student);
            } else if (this.selectedGradingMethod == 2) {
              if (student.latestScoreExam)
                this.useGradingMethod(student.latestScoreExam, student);
            }
          });
        }
      });
    },
    _print(item) {
      console.log("xxx", item, item.ref.path);
      for (const uu of item.unelgee1) {
        console.log(uu.score, uu.ref.path);
      }
    },

    _deleteItem(item) {
      if (
        this.$attrs.lesson.esisLessonType.esisLessonTypeId > 1 &&
        item.lessonGroup.allStudentsSelected != true
      ) {
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    _moveStudent(item) {
      this.$swal({
        title:
          "Энэ группээс " +
          item["FIRST_NAME"] +
          ", " +
          item["LAST_NAME"] +
          " сурагчийг хасах уу?",
        text: "<Тийм> гэснээр сурагч группээс хасагдана. Буцааж нэмэх бол АУБ нэмнэ!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (item.ref.path.includes("lesson-groups")) {
            var studIndex = this.$attrs.lesson.students.findIndex(
              (ss) => ss.PERSON_ID == item.PERSON_ID
            );
            item.ref.delete().then(() => {
              this.editedItem = Object.assign({}, this.defaultItem);
              if (studIndex > -1)
                this.$attrs.lesson.students.splice(studIndex, 1);
              this.dialogDelete = false;
            });
          }
        }
      });
    },
    useGradingMethod(exam, item) {
      if (item.scoreState != true && item.sentToManager != true)
        if (
          exam &&
          exam.score != null &&
          exam.score != undefined &&
          typeof exam.score == "number"
        ) {
          item.score = Number(exam.score);
          item.scoreFinal = Number(exam.score);
          item.scoreBasedOnExam = exam;
          if (item.score == item.averageScore) {
            item.scoreBasedOnState = 0;
          } else if (item.score == item.highestScore) {
            item.scoreBasedOnState = 1;
          } else if (item.score == item.latestScore) {
            item.scoreBasedOnState = 2;
          } else {
            item.scoreBasedOnState = false;
          }
          console.log(exam);
          item.modifiedScore = true;
          this.calScoreLevel(item);
          this.$forceUpdate();
        }
    },
    _clearScore(score) {
      // console.log(score.scoreRef.path, score.ref.path, score);
      score.deleted = true;
      score.score = null;
      if (score.score1) {
        score.score1 = null;
      }
      score.scoreState = -1;
      delete score.scoreFinal;
      delete score.scoreLevelNumber;
      delete score.scoreLevelString;
      delete score.sentToManager;
      delete score.approved;
      this.$forceUpdate();

      // score.scoreRef
      //   .update({
      //     score: fb.firestore.FieldValue.delete(),
      //     scoreFinal: fb.firestore.FieldValue.delete(),
      //     scoreLevelNumber: fb.firestore.FieldValue.delete(),
      //     scoreLevelString: fb.firestore.FieldValue.delete(),
      //     sentToManager: fb.firestore.FieldValue.delete(),
      //     approved: fb.firestore.FieldValue.delete(),
      //   })
      //   .then(() => {
      //     if (score.sentToManager != undefined) delete score.sentToManager;
      //     delete score.score;
      //     delete score.scoreFinal;
      //     delete score.scoreLevelNumber;
      //     delete score.scoreLevelString;
      //     delete score.approved;
      //     score.scoreState = -1;

      //     var deletingScore;
      //     // if (this.$attrs.lesson.esisLessonType.esisLessonTypeId > 1) {
      //     //   deletingScore = this.$attrs.lesson.unelgee2Scores.find(
      //     //     (dscore) =>
      //     //       dscore.PERSON_ID == score.PERSON_ID &&
      //     //       dscore.lessonGroupId == score.lessonGroup.id
      //     //   );
      //     // } else {
      //     //   deletingScore = this.$attrs.lesson.unelgee2Scores.find(
      //     //     (dscore) => dscore.PERSON_ID == score.PERSON_ID
      //     //   );
      //     // }

      //     deletingScore = this.$attrs.lesson.unelgee2Scores.find(
      //       (dscore) => dscore.PERSON_ID == score.PERSON_ID
      //     );

      //     if (deletingScore) {
      //       console.log(deletingScore);
      //       deletingScore.deleted = true;
      //     }
      //     this.calculateAverageScore();
      //     this.$forceUpdate();
      //   });
    },
    getUnelgee1Scores(student) {
      var scores = this.$attrs.lesson.unelgee1Scores.filter(
        (score) =>
          score.PERSON_ID == student.PERSON_ID &&
          score.courseInfo.COURSE_ID == this.$attrs.lesson.courseInfo.COURSE_ID
      );

      scores = scores.sort((a, b) => a.examDate - b.examDate);
      if (scores.length > 0) {
        var highestScore = null;
        var averageScore = 0;
        var latestScore = null;
        scores.forEach((score) => {
          if (
            (highestScore && highestScore.score < score.score) ||
            highestScore == null
          ) {
            highestScore = score;
          }
          if (
            (latestScore && latestScore.examDate < score.examDate) ||
            latestScore == null
          ) {
            latestScore = score;
          }
          averageScore = averageScore + Number(score.score);
        });

        averageScore = Number((averageScore / scores.length).toFixed(0));
        student.highestScore = highestScore.score;
        student.highestScoreExam = highestScore;
        student.latestScore = latestScore.score;
        student.latestScoreExam = latestScore;
        student.unelgee1Scores = scores;
        student.averageScore = averageScore;

        return averageScore;
      } else {
        return "-";
      }
    },
    checkValue(item) {
      if (this.previousValue) {
        if (item.score > 100) {
          item.score = this.previousValue;
        } else {
          if (
            String(item.score) != String(this.previousValue)[0] &&
            item.score != null &&
            item.score != undefined &&
            typeof item.score == "number"
          )
            this.previousValue = item.score;
        }
      } else {
        if (item.score > 100) {
          item.score = 100;
          this.previousValue = 100;
        }
      }
      this.calculateAverageScore();
    },
    calculateAverageScore() {
      var lessonAverageSum = 0;
      var lessonAverageGradedScoreCount = 0;
      this.lessonAverageScore = 0;

      for (var student of this.filteredStudents) {
        if (student.score != undefined && typeof student.score == "number") {
          lessonAverageSum += student.score;
          lessonAverageGradedScoreCount++;
        }
      }
      if (lessonAverageSum > 0 && lessonAverageGradedScoreCount > 0)
        this.lessonAverageScore = Number(
          (lessonAverageSum / lessonAverageGradedScoreCount).toFixed(1)
        );
      if (this.lessonAverageScore == "NaN") {
        this.lessonAverageScore = "-";
      }
    },
    //25132
    getStudentScoreState(student) {
      if (this.$attrs.lesson.unelgee2Scores) {
        var score;
        score = this.$attrs.lesson.unelgee2Scores.find(
          (score) => score.PERSON_ID == student.PERSON_ID
        );
        // console.log(score);
        if (
          score &&
          score.score != null &&
          score.score != undefined &&
          typeof score.score == "number"
        ) {
          //1230 end toFixed(1) oruulsan uchir n' haana tsegees hoish too garch irsen. zuwuu?
          student.score = score.score
            ? parseFloat(score.score.toFixed(1))
            : score.score;
          student.scoreFinal = score.score;
          student.scoreRef = score.ref;
          student.scoreLevelString = score.scoreLevelString;

          if (score.unelgee1) {
            student.unelgee1 = score.unelgee1;
          }
          if (score.score1) {
            student.score1 = score.score1;
          }
          if (score.eScore) {
            student.eScore = score.eScore;
          }

          if (score.approved != undefined) student.approved = score.approved;
          if (student.sentToManager != undefined)
            student.sentToManager = score.sentToManager;
          if (score.approved == true) {
            student.scoreState = true;
          } else if (score.approved == false) {
            student.scoreState = false;
          } else {
            if (score.sentToManager) {
              student.sentToManager = true;
            } else {
              student.sentToManager = false;
            }
            student.scoreState = null;
          }
        } else {
          student.scoreState = -1;
        }
      }
    },
  },

  _print(item) {
    console.log("xxx", item.ref.path, item.scoreRef.path);
  },
};
</script>
<style>
.inProgressButton {
  color: #ffbf00 !important;
}

.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 35px !important;
  padding: 0 !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}

.score-bordered-input .v-input__control {
  border: 1px solid #000;
  /* Customize border style here */
  border-radius: 4px;
  /* Optional: Add border radius */
}
</style>
